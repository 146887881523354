import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-tubing-detail',
  templateUrl: './tubing-detail.component.html',
  styleUrls: ['./tubing-detail.component.css']
})
export class TubingDetailComponent implements OnInit {

  detail
  constructor(
    private dialogRef: MatDialogRef<TubingDetailComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.detail = data;

  }


  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }
}
