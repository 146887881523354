export class PurchaseOrderRaw {

  private _PO_No: string;
  private _PO_RawMaterials: RawMaterialOrder[];
  private _Status: string;
  private _Created_Date: Date;
  private _Updated_Date: Date;
  private _Created_By: string;
  private _Updated_By: string;
  private _Completed_Date: Date;

  get PO_No(): string {
    return this._PO_No;
  }
  set PO_No(value: string) {
    this._PO_No = value;
  }

  get Status(): string {
    return this._Status;
  }
  set Status(value: string) {
    this._Status = value;
  }

  get PO_RawMaterials(): RawMaterialOrder[] {
    return this._PO_RawMaterials;
  }
  set PO_RawMaterials(value: RawMaterialOrder[]) {
    this._PO_RawMaterials = value;
  }

  get Completed_Date(): Date {
    return this._Completed_Date;
  }
  set Completed_Date(value: Date) {
    this._Completed_Date = value;
  }

  get Created_Date(): Date {
    return this._Created_Date;
  }
  set Created_Date(value: Date) {
    this._Created_Date = value;
  }
  get Updated_Date(): Date {
    return this._Updated_Date;
  }
  set Updated_Date(value: Date) {
    this._Updated_Date = value;
  }

  get Updated_By(): string {
    return this._Updated_By;
  }
  set Updated_By(value: string) {
    this._Updated_By = value;
  }
  get Created_By(): string {
    return this._Created_By;
  }
  set Created_By(value: string) {
    this._Created_By = value;
  }


}


export class ReceivedDetails{
  private _ID: string;
  private _Received_Date: Date;
  private _Rack_Number: string;
  private _Packets: string;
  private _Invoice_Number: string;
  private _Lot_Number: string;
  private _Box_Number: string;
  private _Received_Quantity: string;
  private _Quantity_PerBags: string;
  private _ImageURL : string;

  get Received_Date(): Date {
    return this._Received_Date;
  }
  set Received_Date(value: Date) {
    this._Received_Date = value;
  }

  get Received_Quantity(): string {
    return this._Received_Quantity;
  }
  set Received_Quantity(value: string) {
    this._Received_Quantity = value;
  }

  get ImageURL(): string {
    return this._ImageURL;
  }
  set ImageURL(value: string) {
    this._ImageURL = value;
  }

  get Quantity_PerBags(): string {
    return this._Quantity_PerBags;
  }
  set Quantity_PerBags(value: string) {
    this._Quantity_PerBags = value;
  }
  get ID(): string {
    return this._ID;
  }
  set ID(value: string) {
    this._ID = value;
  }
  get Rack_Number(): string {
    return this._Rack_Number;
  }
  set Rack_Number(value: string) {
    this._Rack_Number = value;
  }
  get Packets(): string {
    return this._Packets;
  }
  set Packets(value: string) {
    this._Packets = value;
  }
  get Invoice_Number(): string {
    return this._Invoice_Number;
  }
  set Invoice_Number(value: string) {
    this._Invoice_Number = value;
  }
  get Lot_Number(): string {
    return this._Lot_Number;
  }
  set Lot_Number(value: string) {
    this._Lot_Number = value;
  }
  get Box_Number(): string {
    return this._Box_Number;
  }
  set Box_Number(value: string) {
    this._Box_Number = value;
  }


}
export class RawMaterialOrder {

  private _PO_RawMaterial_ID: string;
  private _PO_Order_ID: string;
  private _PO_RawMaterial_Name: string;
  private _PO_RawMaterial_Qty: number;
  private _PO_RawMaterial_Status: string;
  private _Supplier: string;
  private _Received_Quantity: string;
  private _Deducted_Quantity: string;
  private _PoInRawMaterialList: PoInRawMaterial[] = []
  private _ReceivedDetailsList: ReceivedDetails[] = []
  private _Unit: string;
  private _Unit_Price: string;
  private _Amount: string;
  private _Delivery_Date: Date;
  public quantity_perBag: string;

  get PoInRawMaterialList(): PoInRawMaterial[] {
    return this._PoInRawMaterialList;
  }
  set PoInRawMaterialList(value: PoInRawMaterial[]) {
    this._PoInRawMaterialList = value;
  }


  get ReceivedDetailsList(): ReceivedDetails[] {
    return this._ReceivedDetailsList;
  }
  set ReceivedDetailsList(value: ReceivedDetails[]) {
    this._ReceivedDetailsList = value;
  }

  get PO_RawMaterial_ID(): string {
    return this._PO_RawMaterial_ID;
  }
  set PO_RawMaterial_ID(value: string) {
    this._PO_RawMaterial_ID = value;
  }
  get PO_Order_ID(): string {
    return this._PO_Order_ID;
  }
  set PO_Order_ID(value: string) {
    this._PO_Order_ID = value;
  }

  get PO_RawMaterial_Name(): string {
    return this._PO_RawMaterial_Name;
  }
  set PO_RawMaterial_Name(value: string) {
    this._PO_RawMaterial_Name = value;
  }

  get PO_RawMaterial_Qty(): number {
    return this._PO_RawMaterial_Qty;
  }
  set PO_RawMaterial_Qty(value: number) {
    this._PO_RawMaterial_Qty = value;
  }

  get PO_RawMaterial_Status(): string {
    return this._PO_RawMaterial_Status;
  }
  set PO_RawMaterial_Status(value: string) {
    this._PO_RawMaterial_Status = value;
  }
  get Supplier(): string {
    return this._Supplier;
  }
  set Supplier(value: string) {
    this._Supplier = value;
  }

  get Received_Quantity(): string {
    return this._Received_Quantity;
  }
  set Received_Quantity(value: string) {
    this._Received_Quantity = value;
  }
  get Deducted_Quantity(): string {
    return this._Deducted_Quantity;
  }
  set Deducted_Quantity(value: string) {
    this._Deducted_Quantity = value;
  }


  get Unit(): string {
    return this._Unit;
  }
  set Unit(value: string) {
    this._Unit = value;
  }

  get Unit_Price(): string {
    return this._Unit_Price;
  }
  set Unit_Price(value: string) {
    this._Unit_Price = value;
  }

  get Amount(): string {
    return this._Amount;
  }
  set Amount(value: string) {
    this._Amount = value;
  }

  get Delivery_Date(): Date {
    return this._Delivery_Date;
  }
  set Delivery_Date(value: Date) {
    this._Delivery_Date = value;
  }

}

export class PoInRawMaterial {
  _CheckOut_Date: Date;
  _Part_No: string;
  _Po_No: string;
  _CheckOut_Quantity: string;
  _Machine_Assigned: string;

  get CheckOut_Date(): Date {
    return this._CheckOut_Date;
  }
  set CheckOut_Date(value: Date) {
    this._CheckOut_Date = value;
  }

  get Part_No(): string {
    return this._Part_No;
  }
  set Part_No(value: string) {
    this._Part_No = value;
  }
  get Po_No(): string {
    return this._Po_No;
  }
  set Po_No(value: string) {
    this._Po_No = value;
  }
  get CheckOut_Quantity(): string {
    return this._CheckOut_Quantity;
  }
  set CheckOut_Quantity(value: string) {
    this._CheckOut_Quantity = value;
  }
  get Machine_Assigned(): string {
    return this._Machine_Assigned;
  }
  set Machine_Assigned(value: string) {
    this._Machine_Assigned = value;
  }
}
