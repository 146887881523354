export class Tubing {

  public PONumber : string;
  public PartNumber: string;
  public CreatedDate: Date;
  public UpdatedDate: Date;
  public ProductionQuantity: number;
  public UpdatedBy: string;
  public TubingInfos: TubingInfo[] = [];
}

export class TubingInfo {
  public TimeStamp: string;
  public TubingAmount: number;
  public TubingDifferent: number;
}
