import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Keyboard from "simple-keyboard";
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './align-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./align-dialog.component.css',
    '../../../../node_modules/simple-keyboard/build/css/index.css',
  ]
})
export class AlignDialogComponent implements OnInit {

  form: FormGroup;
  value = "";
  keyboard: Keyboard;
  title: string;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AlignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.title = data;
  }

  onChange = (input: string) => {
    this.value = input;
  };

  onKeyPress = (button: string) => {

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
  };

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };

  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      layout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "0 . {backspace}"]
      },
      display: {
        "{backspace}": "clear",
        theme: "hg-theme-default hg-layout-numeric numeric-theme",
      },
    });

  }

  ngOnInit() {
    this.form = this.fb.group({
      quantity: ['']
    });
  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if(this.value){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = 'auto';

      const position = { top: '5%' };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data = 'Confirm this add ' + this.value + '? ';

      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
        if (data) {
          this.dialogRef.close(this.value);

        }
      });
    }

  }

}
