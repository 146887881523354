import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { StocksComponent } from '../../parts/PopUpModal/stocks/stocks.component';

@Component({
  selector: 'app-downtime-details',
  templateUrl: './downtime-details.component.html',
  styleUrls: ['./downtime-details.component.css']
})
export class DowntimeDetailsComponent implements OnInit {


  job: any[] = [];
  constructor(
    private dialogRef: MatDialogRef<DowntimeDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private changeDef: ChangeDetectorRef

  ) {
    this.job = data;
  }
  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }



}
