import { Tubing } from './../../Services/Object_Classes/Tubing/Tubing';
import { Machine, ScheduleTracker } from './../../Services/Object_Classes/Machine/Machine';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { data } from 'jquery';
import { QtyInformationComponent } from './qty-information/qty-information.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { PartsInfoComponent } from './parts-info/parts-info.component';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Part } from '../../Services/Object_Classes/Part/Part';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { PartTracker, PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { WeightingDB_controller } from '../../Services/DB_Controller/WeightingDB_controller';
import { Weighting } from '../../Services/Object_Classes/Weighting/Weighting';
import { AngularFireStorage } from '@angular/fire/storage';
import { firestore } from 'firebase';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { QCInfoComponent } from './qcinfo/qcinfo.component';
import { JobTubingDetailsComponent } from '../purchaseOrder/job-tubing-details/job-tubing-details.component';

@Component({
  selector: 'app-qc',
  templateUrl: './qc.component.html',
  styleUrls: ['./qc.component.css'],
})
export class QCComponent implements OnInit {
  poFlag = false;
  partNumFlag = false;
  accQtyFlag = false;
  rejQtyFlag = false;
  rejRemFlag = false;
  wDateFlag = false;
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  myControl1 = new FormControl();
  options1: string[] = ['-', 'sink mark', 'short moulding', 'colour out', 'dimension out', 'pin broken', 'warping',
                        'black dot', 'pin mark', 'moisture mark'];
  filteredOptions1: Observable<string[]>;
  currentPONo: string;
  currentPartNum: string;

  TotAccQty: number[] = [];
  TotRejQty: number[] = [];
  RejRemark: string[] = [];
  LastWeightDate: Date[] = [];
  POPartList: PartTracker[] = [];
  POList: PurchaseOrder[] = [];
  PartList: Part[] = [];
  podb_Controller = new PODB_controller(this.db);
  partdb_Controller = new PartDB_controller(this.db,this.storage,this.firestore);
  weighting_Controller = new WeightingDB_controller(this.db);
  machine_controller = new MachineDB_controller(this.db);


  //Pagination record
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: 'weightDate',
    direction: 'desc'
  };
  WeightingRecordList: Weighting[] = [];
  CloneWeightingRecordList: Weighting[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: false }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: false }) bottomPaginator: MatPaginator;


  //Pagination from stock record
  stocklength = 0;
  stockpageSize = 10;
  stockpageSizeOptions: number[] = [10, 25, 50];
  stockpageIndex = 0;
  stockoffset = this.stockpageSize * this.stockpageIndex;
  stocksearch: string;
  stocksortedu = {
    active: '',
    direction: ''
  };
  stockWeightingRecordList: Weighting[] = [];
  stockCloneWeightingRecordList: Weighting[] = [];
  @ViewChild('stocktopPaginator', { read: MatPaginator, static: false }) stocktopPaginator: MatPaginator;
  @ViewChild('stockbottomPaginator', { read: MatPaginator, static: false }) stockbottomPaginator: MatPaginator;


  //Pagination Machine job
  lengthJob = 0;
  pageSizeJob = 10;
  pageSizeOptionsJob: number[] = [10, 25, 50];
  pageIndexJob = 0;
  offsetJob = this.pageSizeJob * this.pageIndexJob;
  searchJob: string;
  sorteduJob = {
    active: '',
    direction: ''
  };
  Job: ScheduleTracker[] = [];
  CloneJob: ScheduleTracker[] = [];
  @ViewChild('topPaginatorJob', { read: MatPaginator, static: false }) topPaginatorJob: MatPaginator;
  @ViewChild('bottomPaginatorJob', { read: MatPaginator, static: false }) bottomPaginatorJob: MatPaginator;


  QClog = []
  Weightlog = []
  Shiplog = []

  qcLength = 10;
  weightLength = 10;
  shipLength = 10;

  stockIn = 0;
  stockOut = 0;
  datePicker: Date
  FromPicker: Date

  monthDate: Date;
  firstDayMonth:Date;
  LastDayMonth:Date;

  previousStockIn = 0;
  previousStockOut = 0;

  isFetching = true;
  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private excelHelper: ExcelHelperService,
    private spinner: NgxSpinnerService,
    private toast : ToastrService,
    private storage:AngularFireStorage,
    private firestore: AngularFirestore
  ) {
    this.monthDate= new Date();
    var totalDay = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1, 0).getDate();
    const temp = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth(), 1);
    this.firstDayMonth = new Date(temp);
    this.firstDayMonth.setHours(0)
    this.firstDayMonth.setMinutes(0)
    this.firstDayMonth.setSeconds(0)
    this.LastDayMonth = new Date(temp.setDate(temp.getDate()+totalDay-1));
    this.LastDayMonth.setHours(23)
    this.LastDayMonth.setMinutes(59)
    this.LastDayMonth.setSeconds(59)
  }

  machineList:Machine[]= [];
  async ngOnInit() {
    this.datePicker = new Date();
    this.FromPicker = new Date(new Date().setMonth(this.datePicker.getMonth()-1));
    this.db.database.ref('Machine').on('value', async(childSnapshot)=>{
      this.machineList = await this.machine_controller.getMachineListForQC(childSnapshot);
      for (const machine of this.machineList) {
        machine.Schedule_Track = machine.Schedule_Track.sort((a,b)=>{
          return this.compareDate(a.DateFormatStart, b.DateFormatStart, false);
        })
      }

      this.machineList = this.machineList.sort((a,b)=> {
        return this.compareDate(a.Machine_No, b.Machine_No, true);
      })
    })

    setTimeout(() => {
      this.isFetching = false;
    }, 2000);
    this.setup();
    this.getQCLog();
    this.getWeightLog();
    this.getShipLog();
    this.getDashboard();

  }

  initJob() {

    this.machine_controller.getMachineListForCompletedJob().then(data => {
      this.Job = data;
      this.lengthJob = this.Job.length;
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      this.spinner.hide();
    })
  }

  NoJob(schedules){
    return schedules.filter(s=>s.QCDone).length === 0?true:false;
  }
  machineInfo = [];
  getDashboard(){
    this.getLog()
    this.getPreviousMonthLog()
    this.getMachineLog();
  }

  refresh(){
    this.setup();
  }

  getMachineLog(){
    const fromdate = firestore.Timestamp.fromDate(this.firstDayMonth);
    const toDate = firestore.Timestamp.fromDate(this.LastDayMonth);
    this.firestore.collection('QualityCheckLog',
    ref => ref.where('Date','>=',fromdate)
            .where('Date','<=',toDate)).get().forEach(data => {
        data.forEach(e => {
        this.firestore.collection('QualityCheckLog').doc(e.id).
        collection('PO').get().forEach(poLog => {

          const logs = [];
          poLog.forEach(log => {

            let rej = []
            let rejQty = 0
            if(log.data()['Rejected Information']){
              rej = Object.values(log.data()['Rejected Information']);
            }
            rej.forEach(element => {
              rejQty += element;
            });
            const info ={
              date : new Date(log.data().Date),
              acceptyQty: log.data()['Accepted Quantity'],
              PO:  log.data().PO_ID,
              Part: log.data().Part_ID,
              rejQty: rejQty,
            }
            logs.push(info);
          });

          logs.forEach(async e=>{
            // var snapshot = await this.db.database.ref('Purchase Order/'+e.PO + '/Part List').once('value');
            // if(snapshot.exists()){
            //   snapshot.forEach(childsnapshot=>{
            //     if(childsnapshot.child('Part No').val() === e.Part){
            //       if(childsnapshot.child('MachineNo').val()){
            //         const ind = this.machineList.findIndex(m=>childsnapshot.child('MachineNo').val().includes(m));

            //         if(ind !== -1){
            //           this.machineInfo[ind].acceptyQty += e.acceptyQty;
            //           this.machineInfo[ind].rejQty += e.rejQty;
            //         }
            //       }
            //     }
            //   })
            // }
          })
        });
      });
    });
  }
  getLog(){
    this.stockIn= 0;
    this.stockOut= 0;
    const fromdate = firestore.Timestamp.fromDate(this.firstDayMonth);
    const toDate = firestore.Timestamp.fromDate(this.LastDayMonth);
    this.firestore.collection('WeightingLog',
    ref => ref.where('Date','>=',fromdate)
    .where('Date','<=',toDate)
    ).get().forEach(data => {
      data.forEach(e => {
        const d = new Date(e.data().Date)
        const currentD = new Date();
        let flag =( d.getFullYear() === currentD.getFullYear()) &&( d.getMonth() === currentD.getMonth()) &&( d.getDate() === currentD.getDate())
        if(e.data().totalStockIn && !flag){
          this.stockIn += e.data().totalStockIn
          this.stockOut += e.data().totalStockOut
        }else{
          this.firestore.collection('WeightingLog').doc(e.id).
          collection('PO').get().forEach(poLog => {
            var totalStockIn = 0;
            var totalStockOut = 0;
            poLog.forEach(log => {
              if(log.data()['MadeToOrder'] || log.data()['MakeToStock']){
                if(parseFloat(log.data()['MadeToOrder']) !== 0){
                  this.stockOut += parseFloat(log.data()['MadeToOrder'])
                  totalStockOut += parseFloat(log.data()['MadeToOrder'])
                }else {
                  this.stockIn += parseFloat(log.data()['MakeToStock'])
                  totalStockIn += parseFloat(log.data()['MakeToStock'])
                }
              }
            });
            const updateInfo = {
              totalStockIn,
              totalStockOut
            }
            this.firestore.collection('WeightingLog').doc(e.id).update(updateInfo);
          });
        }
      });
    });
  }

  getPreviousMonthLog(){
    this.previousStockIn= 0;
    this.previousStockOut= 0;
    const first = new Date(this.firstDayMonth);
    const last = new Date(this.LastDayMonth);
    first.setMonth(first.getMonth()-1)
    last.setMonth(last.getMonth()-1)

    const fromdate = firestore.Timestamp.fromDate(first);
    const toDate = firestore.Timestamp.fromDate(last);
    this.firestore.collection('WeightingLog',
    ref => ref.where('Date','>=',fromdate)
    .where('Date','<=',toDate)
    ).get().forEach(data => {
      data.forEach(e => {
        if(e.data().totalStockIn){
          this.previousStockIn += e.data().totalStockIn
          this.previousStockOut += e.data().totalStockOut
        }else{
          this.firestore.collection('WeightingLog').doc(e.id).
          collection('PO').get().forEach(poLog => {
            var totalStockIn = 0;
            var totalStockOut = 0;
            poLog.forEach(log => {
              if(log.data()['MadeToOrder'] || log.data()['MakeToStock']){
                if(parseFloat(log.data()['MadeToOrder']) !== 0){
                  this.previousStockOut += parseFloat(log.data()['MadeToOrder'])
                  totalStockOut += parseFloat(log.data()['MadeToOrder'])
                }else {
                  this.previousStockIn += parseFloat(log.data()['MakeToStock'])
                  totalStockIn += parseFloat(log.data()['MakeToStock'])
                }
              }
            });
            const updateInfo = {
              totalStockIn,
              totalStockOut
            }
            this.firestore.collection('WeightingLog').doc(e.id).update(updateInfo);
          });
        }

      });
    });
  }

  logs= [];
  searchLog(){
    this.getQCLog();
    this.getWeightLog();
    this.getShipLog();
  }
  getWeightLog(){
    this.Weightlog = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);
    this.firestore.collection('WeightingLog',
    ref => ref.where('Date','>=',fromdate)
            .where('Date','<=',toDate)
            .orderBy('Date', 'desc').limit(10)).get().forEach(data => {
      data.forEach(e => {

        if(this.Weightlog.length > this.weightLength){
          return;
        }
        this.firestore.collection('WeightingLog').doc(e.id).
        collection('PO', ref => ref.orderBy('Date', 'desc').limit(10)).get().forEach(poLog => {
          poLog.forEach(log => {
            if(this.Weightlog.length > this.weightLength){
              return;
            }

            const madeToOrder = parseFloat(log.data()["MadeToOrder"])!==0?true:false;

            const info ={
              date : new Date(log.data().Date),
              packQty: madeToOrder?log.data()['MadeToOrder']:log.data()['MakeToStock'],
              PO:  log.data().PO_ID,
              Part: log.data().Part_ID,
              status: madeToOrder?"Made To Order":"Made To Stock",
              RackingNo: log.data().RackingNo,
              WarehouseLocation: log.data().WarehouseLocation,
            }

            this.Weightlog.push(info);
          });
        });
      });
    });
  }
  getQCLog(){
    this.QClog = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);
    this.firestore.collection('QualityCheckLog',
    ref => ref.where('Date','>=',fromdate)
            .where('Date','<=',toDate)
            .orderBy('Date', 'desc').limit(10)).get().forEach(data => {
        data.forEach(e => {
        if(this.QClog.length > this.qcLength){
          return;
        }
        this.firestore.collection('QualityCheckLog').doc(e.id).
        collection('PO', ref => ref.orderBy('Date', 'desc').limit(10)).get().forEach(poLog => {
          poLog.forEach(log => {
            if(this.QClog.length > this.qcLength){
              return;
            }
            let rej = []
            let rejQty = 0
            if(log.data()['Rejected Information']){
              rej = Object.values(log.data()['Rejected Information']);
            }
            rej.forEach(element => {
              rejQty += element;
            });
            const info ={
              date : new Date(log.data().Date),
              acceptyQty: log.data()['Accepted Quantity'],
              PO:  log.data().PO_ID,
              Part: log.data().Part_ID,
              rejQty: rejQty,
            }
            this.QClog.push(info);
          });
        });
      });
    });
  }

  getShipLog(){
    this.Shiplog = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);
    this.firestore.collection('ShippingLog',
    ref => ref.where('Date','>=',fromdate)
            .where('Date','<=',toDate)
            .orderBy('Date', 'desc').limit(10)).get().forEach(data => {
      data.forEach(e => {
        if(this.Shiplog.length > this.shipLength){
          return;
        }
        this.firestore.collection('ShippingLog').doc(e.id).
        collection('PO', ref => ref.orderBy('Date', 'desc').limit(10)).get().forEach(poLog => {
          poLog.forEach(log => {
            if(this.Shiplog.length > this.shipLength){
              return;
            }

            const info ={
              date : new Date(log.data().Date),
              qty:log.data()['Part Qty'],
              PO:  log.data().PO_ID,
              Part: log.data()['Part Number'],
              updatedBy:log.data().UpdateBy
            }

            this.Shiplog.push(info);
          });
        });
      });
    });
  }



  setup(){
    this.initJob();
    this.spinner.show();
    this.weighting_Controller.getWeightingList().then(data => {
      this.WeightingRecordList = data;
      this.CloneWeightingRecordList = this.WeightingRecordList.slice();
      this.length = this.WeightingRecordList.length;
      this.sortRecordList();
      this.limitList();
      this.spinner.hide();

    });

  }

  DynamicSearchRecord(RecordName: string): void {
    this.search = RecordName;
    this.CloneWeightingRecordList = this.WeightingRecordList.filter(u => {
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.Weight_Part_List) {
        if (part.Part_No.toLowerCase().includes(this.search.toLowerCase()) || (part.UpdateBy.toLowerCase().includes(this.search.toLowerCase())))
          return true;
      }
      return flag

    });
    this.length = this.CloneWeightingRecordList.length;
    this.sortRecordList();
    this.limitList();
  }
  DS() {
    this.CloneWeightingRecordList = this.WeightingRecordList.filter(u => {
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.Weight_Part_List) {
        if (part.Part_No.toLowerCase().includes(this.search.toLowerCase()) || (part.UpdateBy.toLowerCase().includes(this.search.toLowerCase())))
          return true;
      }
      return flag

    });
    this.length = this.CloneWeightingRecordList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.CloneWeightingRecordList = this.WeightingRecordList.slice();
    }
    this.sortRecordList();
    this.limitList();
  }
  limitList() {
    this.CloneWeightingRecordList = this.CloneWeightingRecordList.slice(this.offset, (this.offset + this.pageSize));
  }
  sortRecordList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.CloneWeightingRecordList = this.CloneWeightingRecordList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNo': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'partNo': return this.compare(a.Weight_Part_List[0].Part_No, b.Weight_Part_List[0].Part_No, isAsc);
        case 'acceptedQty': return this.compare(a.Weight_Part_List[0].Accept_Qty, b.Weight_Part_List[0].Accept_Qty, isAsc);
        case 'rejectedQty': return this.compare(a.Weight_Part_List[0].Reject_Qty, b.Weight_Part_List[0].Reject_Qty, isAsc);
        case 'working': return this.compare(a.Weight_Part_List[0].WorkingArea, b.Weight_Part_List[0].WorkingArea, isAsc);
        case 'updateBy': return this.compare(a.Weight_Part_List[0].UpdateBy, b.Weight_Part_List[0].UpdateBy, isAsc);
        case 'qcDate': return this.compareDate(a.Weight_Part_List[0].Weight_Date, b.Weight_Part_List[0].Weight_Date, isAsc);
        case 'weightDate': return this.compareDate(a.Weight_Part_List[0].WeightingInfo.Updated_Date, b.Weight_Part_List[0].WeightingInfo.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }
  sortData(sort: Sort) {
    this.sortedu = sort;
    this.CloneWeightingRecordList = this.WeightingRecordList.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.CloneWeightingRecordList = this.WeightingRecordList.slice();
      this.limitList();
      return;
    }

    this.CloneWeightingRecordList = this.CloneWeightingRecordList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNo': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'partNo': return this.compare(a.Weight_Part_List[0].Part_No, b.Weight_Part_List[0].Part_No, isAsc);
        case 'acceptedQty': return this.compare(a.Weight_Part_List[0].Accept_Qty, b.Weight_Part_List[0].Accept_Qty, isAsc);
        case 'rejectedQty': return this.compare(a.Weight_Part_List[0].Reject_Qty, b.Weight_Part_List[0].Reject_Qty, isAsc);
        case 'working': return this.compare(a.Weight_Part_List[0].UpdateBy, b.Weight_Part_List[0].UpdateBy, isAsc);
        case 'updateBy': return this.compare(a.Weight_Part_List[0].WorkingArea, b.Weight_Part_List[0].WorkingArea, isAsc);
        case 'qcDate': return this.compareDate(a.Weight_Part_List[0].Weight_Date, b.Weight_Part_List[0].Weight_Date, isAsc);
        case 'weightDate': return this.compareDate(a.Weight_Part_List[0].WeightingInfo.Updated_Date, b.Weight_Part_List[0].WeightingInfo.Updated_Date, isAsc);

        default: return 0;
      }
    });
    this.limitList();
  }



  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  viewParts(record){
    this.dialog.open(PartsInfoComponent, {
      width: '80%',
      height: '80%',
      data: record
    });
  }

  GetActivity(value){
    this.qcLength = value;
    this.getQCLog();
  }

  GetActivity2(value){
    this.weightLength = value;
    this.getWeightLog();
  }

  GetActivity3(value){
    this.shipLength = value;
    this.getShipLog();
  }


  detectChanges(event) {
    this.monthDate = event;
    var totalDay = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1, 0).getDate();
    const temp = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth(), 1);
    this.firstDayMonth = new Date(temp);
    this.firstDayMonth.setHours(0)
    this.firstDayMonth.setMinutes(0)
    this.firstDayMonth.setSeconds(0)
    this.LastDayMonth = new Date(temp.setDate(temp.getDate()+totalDay-1));
    this.LastDayMonth.setHours(23)
    this.LastDayMonth.setMinutes(59)
    this.LastDayMonth.setSeconds(59)
    this.getDashboard();
  }
  Nan(value) {
    if (isNaN(value))
      return 0
    else
      return value
  }


  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    const weight = this.WeightingRecordList.sort((a,b)=>{
      return (a.PO_No < b.PO_No ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const w of weight) {
      for (const part of w.Weight_Part_List) {
        const info ={
          "PO Number":w.PO_No || "-",
          "Part Number": part.Part_No || "-",
          "Accepted Quantity":part.Accept_Qty || 0,
          "Made to Order": part.WeightingInfo? part.WeightingInfo.MadeToOrder: "Not Weighting Yet",
          "Make to Stock": part.WeightingInfo? part.WeightingInfo.MakeToStock: "Not Weighting Yet",
          "Rejected Quantity":part.Reject_Qty || 0,
          "Rejected Reason":part.RejectedInfos.map(v=>v.Code).join(','),
          "Working Area":part.WorkingArea || "",
          "Updated By":part.UpdateBy || "-",
          "QC Date":part.Weight_Date|| "-",
        }
        exportInformation.push(info)
      }
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'QC'+new Date().getTime());
    this.spinner.hide();
  }


  view(part, statement) {
    var info;
    switch (statement) {
      case 'accept':
        info = {
          title: 'Information',
          data: [{
            'label': 'Made To Order',
            'value': part.MadeToOrder,
            'size': 'col-lg-12'
          },
          {
            'label': 'Make To Stcck',
            'value': part.MakeToStock,
            'size': 'col-lg-12'
          },
          ]
        }
        break;
        case 'reject':
        info = {
          title: 'Information',
          data: [],
        }
        for (const rejectInfo of part) {
          let i = {
            'label': rejectInfo.Code,
            'value': rejectInfo.Quantity,
            'size': 'col-lg-12'
          }
          info.data.push(i);
        }
        break;
    }
    this.dialog.open(QtyInformationComponent, {
      width: '50%',
      height: 'auto',
      data: info
    });

  }
  viewAll(machine: Machine){
    this.dialog.open(QCInfoComponent, {
      width: '80%',
      height: '80%',
      data: {
        title: machine.Machine_No + " All PO",
        tableData: machine.Schedule_Track
      }
    });
  }

  // viewAllNotYet(machine: Machine){
  //   this.dialog.open(QCInfoComponent, {
  //     width: '80%',
  //     height: '80%',
  //     data: {
  //       title: machine.Machine_No + " All Pending PO",
  //       tableData: machine.Schedule_Track.filter(e=>!e.QCDone)
  //     }
  //   });
  // }

  calculateTubing(tubing: Tubing){
    return tubing.TubingInfos.map(a=>a.TubingAmount).reduce((a, b) => { return a + b }, 0)
  }

  calculateDiff(tubing:Tubing, autoTubing:Tubing){
    let manualTotal =  tubing.TubingInfos.map(a=>a.TubingAmount).reduce((a, b) => { return a + b }, 0);
    let autoTotal =  autoTubing.TubingInfos.map(a=>a.TubingAmount).reduce((a, b) => { return a + b }, 0);

    if(manualTotal === 0 || autoTotal === 0){
      return 0
    }
    else{
      if(autoTotal > manualTotal){
        return ((autoTotal-manualTotal) /autoTotal) * 100
      }
      else{
        return ((manualTotal-autoTotal) /manualTotal) * 100
      }
    }
      

  }


  viewTubing(job: ScheduleTracker) {
    this.dialog.open(JobTubingDetailsComponent, {
      width: '80%',
      height: 'auto',
      data: job,
      disableClose: true
    });
  }
  export(schedule: ScheduleTracker) {
    this.spinner.show();
    let json1 = [];
    let json2 = [];
    let json3 = [];
    json1 = [{
      "Purchase Order": schedule.Machine_Schedule_PO_No || "-",
      "Part Name": schedule.Machine_Schedule_Part_No || "-",
      "Production Quantity": schedule.Exp_Qty || "-",
      "Total Weight": this.calculateTubing(schedule.autotubingInfo).toFixed(2),
      "Total Tubing Weight": this.calculateTubing(schedule.tubingInfo).toFixed(2),
      "Cycle Time": schedule.CycleTime || "-",
      "Start Time": schedule.DateFormatStart || "-",
      "End Time": schedule.DateFormatEnd || "-",
    }]

    for (const totalWeight of schedule.autotubingInfo.TubingInfos) {
      let info = {
        Time : new Date(parseInt(totalWeight.TimeStamp)),
        Weight: totalWeight.TubingAmount
      }
      json2.push(info)
    }

    for (const totalWeight of schedule.tubingInfo.TubingInfos) {
      let info = {
        Time : new Date(totalWeight.TimeStamp),
        Weight: totalWeight.TubingAmount
      }
      json3.push(info)
    }

    this.excelHelper.export3Sheet(json1,json2,json3, schedule.Machine_Schedule_PO_No + new Date().getTime());
    this.spinner.hide();
  }
  DynamicSearchJob(job: string): void {
    this.searchJob = job;
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.Machine_Schedule_PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.PartName).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.Machine_Schedule_Part_No).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.CycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
    );
    this.lengthJob = this.CloneJob.length;
    this.sortJob();
    this.limitListJob();
  }
  DSJob() {
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.Machine_Schedule_PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.PartName).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.Machine_Schedule_Part_No).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.CycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
    );
    this.lengthJob = this.CloneJob.length;

  }

  paginatorJob(pageEvent: PageEvent) {
    this.pageSizeJob = pageEvent.pageSize;
    this.pageIndexJob = pageEvent.pageIndex;
    this.offsetJob = this.pageSizeJob * this.pageIndexJob;
    if (this.topPaginatorJob.pageIndex < this.pageIndexJob) {
      this.topPaginatorJob.nextPage();
    } else if (this.topPaginatorJob.pageIndex > this.pageIndexJob) {
      this.topPaginatorJob.previousPage();
    }
    if (this.bottomPaginatorJob.pageIndex < this.pageIndexJob) {
      this.bottomPaginatorJob.nextPage();
    } else if (this.bottomPaginatorJob.pageIndex > this.pageIndexJob) {
      this.bottomPaginatorJob.previousPage();
    }
    if (this.searchJob) {
      this.DSJob();
    } else {
      this.CloneJob = this.Job.slice();
    }
    this.sortJob();
    this.limitListJob();
  }
  limitListJob() {
    this.CloneJob = this.CloneJob.slice(this.offsetJob, (this.offsetJob + this.pageSizeJob));
  }
  sortJob() {
    if (!this.sorteduJob.active || this.sorteduJob.direction === '') {
      return;
    }
    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.Machine_Schedule_PO_No, b.Machine_Schedule_PO_No, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.Machine_Schedule_Part_No, b.Machine_Schedule_Part_No, isAsc);
        case 'partName': return this.compare(a.PartName, b.PartName, isAsc);
        case 'status': return this.compare(a.Machine_Schedule_Status, b.Machine_Schedule_Status, isAsc);
        case 'PredefinedCycle': return this.compare(a.CycleTime, b.CycleTime, isAsc);
        case 'startTime': return this.compareDate(a.DateFormatStart, b.DateFormatStart, isAsc);
        case 'endTime': return this.compareDate(a.DateFormatEnd, b.DateFormatEnd, isAsc);
        case 'presetTime': return this.compare(parseFloat(a.DatePresettingEnd) - parseFloat(a.DatePresettingStart), parseFloat(b.DatePresettingEnd) - parseFloat(b.DatePresettingStart), isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
        case 'totalWeight': return this.compare(this.calculateTubing(a.autotubingInfo), this.calculateTubing(b.autotubingInfo), isAsc);
        case 'totalTubingWeight': return this.compare(this.calculateTubing(a.tubingInfo), this.calculateTubing(b.tubingInfo), isAsc);

        default: return 0;
      }
    });
  }
  sortDataJob(sort: Sort) {
    this.sorteduJob = sort;
    this.CloneJob = this.Job.slice();
    if (this.searchJob) {
      this.DSJob();
    }
    if (!sort.active || sort.direction === '' && !this.searchJob) {
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      return;
    }

    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.Machine_Schedule_PO_No, b.Machine_Schedule_PO_No, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.Machine_Schedule_Part_No, b.Machine_Schedule_Part_No, isAsc);
        case 'partName': return this.compare(a.PartName, b.PartName, isAsc);
        case 'PredefinedCycle': return this.compare(a.CycleTime, b.CycleTime, isAsc);
        case 'status': return this.compare(a.Machine_Schedule_Status, b.Machine_Schedule_Status, isAsc);
        case 'startTime': return this.compareDate(a.DateFormatStart, b.DateFormatStart, isAsc);
        case 'endTime': return this.compareDate(a.DateFormatEnd, b.DateFormatEnd, isAsc);
        case 'presetTime': return this.compare(parseFloat(a.DatePresettingEnd) - parseFloat(a.DatePresettingStart), parseFloat(b.DatePresettingEnd) - parseFloat(b.DatePresettingStart), isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
        case 'totalWeight': return this.compare(this.calculateTubing(a.autotubingInfo), this.calculateTubing(b.autotubingInfo), isAsc);
        case 'totalTubingWeight': return this.compare(this.calculateTubing(a.tubingInfo), this.calculateTubing(b.tubingInfo), isAsc);
        default: return 0;
      }
    });
    this.limitListJob();
  }

}
