import { CrushingMaterialComponent } from './crushing-material/crushing-material.component';
import { Chart } from 'chart.js';
import { PoStockRecordComponent } from './po-stock-record/po-stock-record.component';
import { OldStock } from './../../Services/Object_Classes/RawMaterial/OldStock';
import { GenerateOldQRCodeComponent } from './generate-old-qrcode/generate-old-qrcode.component';
import { ManageRawTypeComponent } from './manage-raw-type/manage-raw-type.component';
import { SupplierDB_controller } from './../../Services/DB_Controller/SupplierDB_controller';
import { AddSupplierComponent } from './Supplier/add-supplier/add-supplier.component';
import { OrdersComponent } from './PO/orders/orders.component';
import { DetailPOComponent } from './PO/detail-po/detail-po.component';
import { DetailsRawComponent } from './PopUpRaw/details-raw/details-raw.component';
import { GenerateQRCodeComponent } from './generate-qrcode/generate-qrcode.component';
import { ReceivedOrderComponent } from './received-order/received-order.component';
import { PurchaseOrderRaw } from './../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { OrderRawMaterialComponent } from './order-raw-material/order-raw-material.component';
import { RawMaterialInfo } from './../../Services/Object_Classes/RawMaterial/RawMaterial';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddRawComponent } from './PopUpRaw/add-raw/add-raw.component';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { Supplier } from 'src/app/Services/Object_Classes/RawMaterial/Supplier';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { OldStockRecordComponent } from './old-stock-record/old-stock-record.component';
import { firestore } from 'firebase';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { Machine, RawMaterialNeeded, RawMaterialUsed, ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-raw',
  templateUrl: './raw.component.html',
  styleUrls: ['./raw.component.css']
})
export class RawComponent implements OnInit {

  @ViewChild('crush',{read:CrushingMaterialComponent, static:false}) crush:CrushingMaterialComponent;
  pologs:any[]=[];
  rawlogs :any[]=[];
  crushinglogs :any[]=[];


  poLength = 10;
  rawLength = 10;
  crushingLength = 10;

  RawDB_controllers: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  SupplierDB_controller: SupplierDB_controller = new SupplierDB_controller(this.db, this.firestore);
  PORawController: PORawDB_controller = new PORawDB_controller(this.db, this.firestore);

  //Pagination Raw Materials
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  RawList: RawMaterialInfo[] = [];
  CloneRawList: RawMaterialInfo[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: false }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: false }) bottomPaginator: MatPaginator;

  //Pagination PO raw materials
  lengthPO = 0;
  pageSizePO = 10;
  pageSizeOptionsPO: number[] = [10, 25, 50];
  pageIndexPO = 0;
  offsetPO = this.pageSizePO * this.pageIndexPO;
  searchPO: string;
  sorteduPO = {
    active: '',
    direction: ''
  };
  RawListPO: PurchaseOrderRaw[] = [];
  PendingListPO: PurchaseOrderRaw[] = [];
  CompleteListPO: PurchaseOrderRaw[] = [];
  CloneRawListPO: PurchaseOrderRaw[] = [];
  @ViewChild('topPaginatorPO', { read: MatPaginator, static: false }) topPaginatorPO: MatPaginator;
  @ViewChild('bottomPaginatorPO', { read: MatPaginator, static: false }) bottomPaginatorPO: MatPaginator;


  //Pagination supplier
  lengthSup = 0;
  pageSizeSup  = 10;
  pageSizeOptionsSup : number[] = [10, 25, 50];
  pageIndexSup  = 0;
  offsetSup  = this.pageSizeSup  * this.pageIndexSup ;
  searchSup : string;
  sorteduSup  = {
    active: '',
    direction: ''
  };
  Supplier: Supplier[] = [];
  CloneSupplier: Supplier[] = [];
  @ViewChild('topPaginatorSup', { read: MatPaginator, static: false }) topPaginatorSup : MatPaginator;
  @ViewChild('bottomPaginatorSup', { read: MatPaginator, static: false }) bottomPaginatorSup : MatPaginator;
  datePicker: Date
  FromPicker: Date

  monthDate: Date;
  firstDayMonth:Date;
  LastDayMonth:Date;
  pageSizePending = 3;
  pageIndexPending = 0;
  offsetPending = this.pageSizePending * this.pageIndexPending;
  sorteduPending = {
    active: '',
    direction: ''
  };
  pageSizeCompleted = 3;
  pageIndexCompleted = 0;
  offsetCompleted = this.pageSizeCompleted * this.pageIndexCompleted;
  sorteduCompleted = {
    active: '',
    direction: ''
  };
  email: string;
  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private excelHelper: ExcelHelperService,

  ) {
    this.monthDate= new Date();
    var totalDay = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1, 0).getDate();
    const temp = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth(), 1);
    this.firstDayMonth = new Date(temp);
    this.firstDayMonth.setHours(0)
    this.firstDayMonth.setMinutes(0)
    this.firstDayMonth.setSeconds(0)
    this.LastDayMonth = new Date(temp.setDate(temp.getDate()+totalDay-1));
    this.LastDayMonth.setHours(23)
    this.LastDayMonth.setMinutes(59)
    this.LastDayMonth.setSeconds(59)

    this.datePicker = new Date();
    this.FromPicker = new Date(new Date().setMonth(this.datePicker.getMonth()-1));

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.setup();
  }

  pieChart: Chart
  ngAfterViewInit(): void {

    this.pieChart = new Chart('pieChart', {
      plugins: [ChartDataLabels],
      type: 'pie',
      data: {
        labels: ["Pending PO", "Completed PO", "No PO"],
        datasets: [{
          backgroundColor: ['#FCCF1C', '#2AF560', '#8d8d8d', '#f99600', '#00ffc4', '#ff2300', '#e600ff', '#d3ff00'],
          data: [0, 0, 1],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if(ctx.chart.data.labels[ctx.dataIndex] === 'No PO')
                return "";
              if(value === 0)
                return "";
              return value;
            },
            color: '#000',
            font: {
              weight: 'bold',
              size: 16,
            }
          }
        }
      }
    })
  }

  setup() {
    this.spinner.show();
    this.initPO();
    this.initSup();
    this.initOldStock();
    this.initMachine();
  }
  crushingMaterialLength = 0;
  getLength(length) {
    this.crushingMaterialLength = length
  }

  getDashboard(){
    this.PendingListPO = this.RawListPO.filter(r=>r.Status === 'Pending' &&
    r.Created_Date.getTime() >= this.firstDayMonth.getTime() &&
    r.Created_Date.getTime()<= this.LastDayMonth.getTime());
    this.CompleteListPO = this.RawListPO.filter(r=>r.Status === 'Completed'&&
    r.Created_Date.getTime() >= this.firstDayMonth.getTime() &&
    r.Created_Date.getTime()<= this.LastDayMonth.getTime());
    this.clonePendingListPO = this.PendingListPO.slice();
    this.cloneCompleteListPO = this.CompleteListPO.slice();

    if(this.PendingListPO.length > 0 || this.CompleteListPO.length > 0){
      this.pieChart.data.datasets[0].data[2] = 0;
    }else{
      this.pieChart.data.datasets[0].data[2] = 1;
    }
    this.pieChart.data.datasets[0].data[0] = this.PendingListPO.length;
    this.pieChart.data.datasets[0].data[1] = this.CompleteListPO.length;
    this.pieChart.update();

    this.limitCompleted();
    this.limitPending();

  }
  detectChanges(event) {
    this.monthDate = event;
    var totalDay = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1, 0).getDate();
    const temp = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth(), 1);
    this.firstDayMonth = new Date(temp);
    this.firstDayMonth.setHours(0)
    this.firstDayMonth.setMinutes(0)
    this.firstDayMonth.setSeconds(0)
    this.LastDayMonth = new Date(temp.setDate(temp.getDate()+totalDay-1));
    this.LastDayMonth.setHours(23)
    this.LastDayMonth.setMinutes(59)
    this.LastDayMonth.setSeconds(59)
    this.getDashboard();
  }

  refresh(type){
    switch(type){
      case 'raw': this.initPO();this.initOldStock(); break;
      case 'po': this.initPO(); break;
      case 'sup': this.initSup(); break;
      case 'crush': this.crush.refresh(); break;
      case 'monitor': this.initMachine(); break;

    }
  }

  initRaw(){
    this.RawDB_controllers.getRawList().then(data => {
      this.RawList = data;
      for (const raw of this.RawList) {
        this.POStockLocation(raw);
      }
      this.RawList = this.RawList.sort((a, b) => {
        return this.compareDate(a.Last_Updated, b.Last_Updated, false);
      });
      this.length = this.RawList.length;
      this.CloneRawList = this.RawList.slice();
      this.limitList();
      this.spinner.hide();
    });
  }

  checkStock(raw: RawMaterialInfo){
    const result = this.stocklist.find(s=>s.MaterialID === raw.Material_ID);
    if(result){
      return true;
    }else{
      return false;
    }
  }



  checkPOStock(raw: RawMaterialInfo){
    const result = this.RawListPO.find(s=>{
      const r = s.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID);
      if(r)
        return s;
      else
        return null;
    });
    if(result){
      return true;
    }else{
      return false;
    }
  }

  POStockLocation(raw:RawMaterialInfo){
    const result = this.RawListPO.filter(s=>{
      const r = s.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID);
      if(r)
        return r;
    });
    let temp =[]
    raw.POlocation = '';
    result.forEach(e=>{
      const r = e.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID);
      r.ReceivedDetailsList.forEach(detail=>{
        const exist = temp.findIndex(t=>t===detail.Rack_Number);
        if(exist === -1){
          raw.POlocation += detail.Rack_Number + '|';
          temp.push(detail.Rack_Number);
        }
      })
    })
    return temp;
  }

  viewPOStock(raw: RawMaterialInfo){
    const result = this.RawListPO.filter(s=>{
      const r = s.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID);
      if(r)
        return r;
    });

    const info = [];

    result.forEach(e=>{

      const i = {
        PONumber: e.PO_No,
        RawMaterial:e.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID)
      }
      info.push(i);


    })

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '90%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = info;
    this.dialog.open(PoStockRecordComponent, dialogConfig);
  }

  OldStockLocation(raw: RawMaterialInfo){
    const result = this.stocklist.filter(s=>s.MaterialID === raw.Material_ID);

    let location = ""
    if(result.length > 0){
      let temp =[]
      result.forEach((e,index)=>{

        const exist = temp.findIndex(t=>t=== e.Location);
        if(exist === -1 && e.Location){
          if(!location){
            location = "Old Stock: ";
          }
          temp.push(e.Location);
          location += e.Location + "|"
        }
      })
    }
    return location.slice(0,-1)
  }


  viewOldStock(raw: RawMaterialInfo){
    const result = this.stocklist.filter(s=>s.MaterialID === raw.Material_ID).slice();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '90%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = result;
    this.dialog.open(OldStockRecordComponent, dialogConfig);
  }
  stocklist: OldStock[]= [];
  initOldStock(){
    this.RawDB_controllers.getOldStock().then(data => {
      this.stocklist = data;
      this.stocklist.sort
      this.stocklist = this.stocklist.sort((a, b) => {
        return this.compareDate(a._Created_Date, b._Created_Date, false);
      });
    });
  }
  clonePendingListPO=[];
  cloneCompleteListPO=[];

  initPO(){
    this.PORawController.getPORawMaterialList().then(data => {
      this.RawListPO = data;
      this.RawListPO = this.RawListPO.sort((a, b) => {
        return this.compareDate(a.Updated_Date, b.Updated_Date, false);
      });


      this.PendingListPO = this.RawListPO.filter(r=>r.Status === 'Pending' &&
      r.Created_Date.getTime() >= this.firstDayMonth.getTime() &&
      r.Created_Date.getTime()<= this.LastDayMonth.getTime());
      this.CompleteListPO = this.RawListPO.filter(r=>r.Status === 'Completed'&&
      r.Created_Date.getTime() >= this.firstDayMonth.getTime() &&
      r.Created_Date.getTime()<= this.LastDayMonth.getTime());

      this.cloneCompleteListPO = this.CompleteListPO.slice();
      this.clonePendingListPO = this.PendingListPO.slice();

      if(this.PendingListPO.length > 0 || this.CompleteListPO.length > 0){
        this.pieChart.data.datasets[0].data[2] = 0;
      }
      this.pieChart.data.datasets[0].data[0] = this.PendingListPO.length;
      this.pieChart.data.datasets[0].data[1] = this.CompleteListPO.length;
      this.pieChart.update();
      this.lengthPO = this.RawListPO.length;
      this.CloneRawListPO = this.RawListPO.slice();
      this.limitListPO();
      this.limitCompleted();
      this.limitPending();
      this.spinner.hide();
    }).finally(()=>{
      this.initRaw()
    });
  }

  initSup(){
    this.SupplierDB_controller.getSupplierList().then(data => {
      this.Supplier = data;
      this.Supplier = this.Supplier.sort((a, b) => {
        return this.compareDate(a.Last_Updated, b.Last_Updated, false);
      });
      this.lengthSup = this.Supplier.length;
      this.CloneSupplier = this.Supplier.slice();
      this.limitListSup();
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.getPOLog();
    this.getRawLog();
    this.getCrushingLog();
  }

  getPOLog(){
    this.pologs = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);

    this.firestore.collection('PORawMaterialLog',
    ref => ref.where('Date','>=',fromdate)
            .where('Date','<=',toDate)
            .orderBy('Date', 'desc').limit(10)).get().forEach(data => {
      data.forEach(e => {
        if(this.pologs.length > 10){
          return;
        }
        this.firestore.collection('PORawMaterialLog').doc(e.id).
        collection('PO', ref => ref.orderBy('date', 'desc').limit(10)).get().forEach(poLog => {
          poLog.forEach(log => {
            if(this.pologs.length > 10){
              return;
            }
            const info ={
              ...log.data()
            }
            this.pologs.push(info);
          });
        });
      });
    });
  }
  getRawLog(){
    this.rawlogs = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);
    this.firestore.collection('RawMaterialLog',
    ref => ref.where('Date','>=',fromdate)
    .where('Date','<=',toDate)
    .orderBy('Date', 'desc').limit(10)).get().forEach(data => {
      data.forEach(e => {
        if(this.rawlogs.length > 10){
          return;
        }
        this.firestore.collection('RawMaterialLog').doc(e.id).
        collection('Raw', ref => ref.orderBy('date', 'desc').limit(10)).get().forEach(rawlog => {
          rawlog.forEach(log => {
            if(this.rawlogs.length > 10){
              return;
            }
            const info ={
              ...log.data()
            }
            this.rawlogs.push(info);
          });
        });
      });
    });
  }

  getCrushingLog(){
    this.crushinglogs = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);
    this.firestore.collection('CrushingMaterialUpdateLog',
    ref => ref.where('Date','>=',fromdate)
    .where('Date','<=',toDate)
    .orderBy('Date', 'desc').limit(10)).get().forEach(data => {
      data.forEach(e => {
        if(this.crushinglogs.length > 10){
          return;
        }
        this.firestore.collection('CrushingMaterialUpdateLog').doc(e.id).
        collection('Raw', ref => ref.orderBy('Date', 'desc').limit(10)).get().forEach(rawlog => {
          rawlog.forEach(log => {
            if(this.crushinglogs.length > 10){
              return;
            }
            const info ={
              date: new Date(log.data().Date),
              name: log.data()['Raw Material Name'],
              original: log.data().Stock - log.data().Quantity,
              current:log.data().Stock,
              updatedBy: log.data()['Update by']
            }
            this.crushinglogs.push(info);
          });
        });
      });
    });
  }

  DynamicSearchRaw(rawName: string): void {
    this.search = rawName;
    this.CloneRawList = this.RawList.filter(u =>
      String(u.Material_Name).toLowerCase().includes(this.search.toLowerCase())
      || String(u.Raw_Type).toLowerCase().includes(this.search.toLowerCase())
      || String(u.location).toLowerCase().includes(this.search.toLowerCase())
      || String(u.POlocation).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.CloneRawList.length;
    this.sortRawList();
    this.limitList();
  }
  DS() {
    this.CloneRawList = this.RawList.filter(u =>
      String(u.Material_Name).toLowerCase().includes(this.search.toLowerCase())
      || String(u.Raw_Type).toLowerCase().includes(this.search.toLowerCase())
      || String(u.location).toLowerCase().includes(this.search.toLowerCase())
      || String(u.POlocation).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.CloneRawList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.CloneRawList = this.RawList.slice();
    }
    this.sortRawList();
    this.limitList();
  }
  limitList() {
    this.CloneRawList = this.CloneRawList.slice(this.offset, (this.offset + this.pageSize));
  }
  sortRawList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.CloneRawList = this.CloneRawList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'color': return this.compare(a.Material_Color, b.Material_Color, isAsc);
        case 'type': return this.compare(a.Raw_Type, b.Raw_Type, isAsc);
        case 'stock': return this.compare(a.In_Stock, b.In_Stock, isAsc);
        case 'unit': return this.compare(a.Unit, b.Unit, isAsc);
        case 'polocation': return this.compare(a.POlocation, b.POlocation, isAsc);
        case 'oldlocation': return this.compare(a.location, b.location, isAsc);
        case 'unitPrice': return this.compare(a.Unit_Price, b.Unit_Price, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'lastUpdate': return this.compareDate(a.Last_Updated, b.Last_Updated, isAsc);
        case 'name': return this.compare(a.Material_Name, b.Material_Name, isAsc);
        default: return 0;
      }
    });
  }
  sortData(sort: Sort) {
    this.sortedu = sort;
    this.CloneRawList = this.RawList.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.CloneRawList = this.RawList.slice();
      this.limitList();
      return;
    }

    this.CloneRawList = this.CloneRawList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'color': return this.compare(a.Material_Color, b.Material_Color, isAsc);
        case 'type': return this.compare(a.Raw_Type, b.Raw_Type, isAsc);
        case 'stock': return this.compare(a.In_Stock, b.In_Stock, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'polocation': return this.compare(a.POlocation, b.POlocation, isAsc);
        case 'oldlocation': return this.compare(a.location, b.location, isAsc);
        case 'unit': return this.compare(a.Unit, b.Unit, isAsc);
        case 'unitPrice': return this.compare(a.Unit_Price, b.Unit_Price, isAsc);
       case 'lastUpdate': return this.compareDate(a.Last_Updated, b.Last_Updated, isAsc);
        case 'name': return this.compare(a.Material_Name, b.Material_Name, isAsc);
        default: return 0;
      }
    });
    this.limitList();
  }

  DynamicSearchRawPO(POname: string): void {
    this.searchPO = POname;
    this.CloneRawListPO = this.RawListPO.filter(u =>
      String(u.PO_No).toLowerCase().includes(this.searchPO.toLowerCase()));
    this.lengthPO = this.CloneRawListPO.length;
    this.sortRawListPO();
    this.limitListPO();
  }
  DSPO() {
    this.CloneRawListPO = this.RawListPO.filter(u =>
      String(u.PO_No).toLowerCase().includes(this.searchPO.toLowerCase()));
    this.lengthPO = this.CloneRawListPO.length;
  }

  paginatorPO(pageEvent: PageEvent) {
    this.pageSizePO = pageEvent.pageSize;
    this.pageIndexPO = pageEvent.pageIndex;
    this.offsetPO = this.pageSizePO * this.pageIndexPO;
    if (this.topPaginatorPO.pageIndex < this.pageIndexPO) {
      this.topPaginatorPO.nextPage();
    } else if (this.topPaginatorPO.pageIndex > this.pageIndexPO) {
      this.topPaginatorPO.previousPage();
    }
    if (this.bottomPaginatorPO.pageIndex < this.pageIndexPO) {
      this.bottomPaginatorPO.nextPage();
    } else if (this.bottomPaginatorPO.pageIndex > this.pageIndexPO) {
      this.bottomPaginatorPO.previousPage();
    }
    if (this.searchPO) {
      this.DSPO();
    } else {
      this.CloneRawListPO = this.RawListPO.slice();
    }
    this.sortRawListPO();
    this.limitListPO();
  }
  limitListPO() {
    this.CloneRawListPO = this.CloneRawListPO.slice(this.offsetPO, (this.offsetPO + this.pageSizePO));
  }
  sortRawListPO() {
    if (!this.sorteduPO.active || this.sorteduPO.direction === '') {
      return;
    }
    this.CloneRawListPO = this.CloneRawListPO.sort((a, b) => {
      const isAsc = this.sorteduPO.direction === 'asc';
      switch (this.sorteduPO.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }
  sortDataPO(sort: Sort) {
    this.sorteduPO = sort;
    this.CloneRawListPO = this.RawListPO.slice();
    if (this.searchPO) {
      this.DSPO();
    }
    if (!sort.active || sort.direction === '' && !this.searchPO) {
      this.CloneRawListPO = this.RawListPO.slice();
      this.limitListPO();
      return;
    }

    this.CloneRawListPO = this.CloneRawListPO.sort((a, b) => {
      const isAsc = this.sorteduPO.direction === 'asc';
      switch (this.sorteduPO.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
    this.limitListPO();
  }

  DynamicSearchRawSup(Supname: string): void {
    this.searchSup = Supname;
    this.CloneSupplier = this.Supplier.filter(u =>
      String(u.Supplier_Name).toLowerCase().includes(this.searchSup.toLowerCase()));
    this.lengthSup = this.CloneSupplier.length;
    this.sortRawListSup();
    this.limitListSup();
  }
  DSSup() {
    this.CloneSupplier = this.Supplier.filter(u =>
      String(u.Supplier_Name).toLowerCase().includes(this.searchSup.toLowerCase()));
    this.lengthSup = this.CloneSupplier.length;
  }

  paginatorSup(pageEvent: PageEvent) {
    this.pageSizeSup = pageEvent.pageSize;
    this.pageIndexSup = pageEvent.pageIndex;
    this.offsetSup = this.pageSizeSup * this.pageIndexSup;
    if (this.topPaginatorSup.pageIndex < this.pageIndexSup) {
      this.topPaginatorSup.nextPage();
    } else if (this.topPaginatorSup.pageIndex > this.pageIndexSup) {
      this.topPaginatorSup.previousPage();
    }
    if (this.bottomPaginatorSup.pageIndex < this.pageIndexSup) {
      this.bottomPaginatorSup.nextPage();
    } else if (this.bottomPaginatorSup.pageIndex > this.pageIndexSup) {
      this.bottomPaginatorSup.previousPage();
    }
    if (this.searchSup) {
      this.DSSup();
    } else {
      this.CloneSupplier = this.Supplier.slice();
    }
    this.sortRawListSup();
    this.limitListSup();
  }
  limitListSup() {
    this.CloneSupplier = this.CloneSupplier.slice(this.offsetSup, (this.offsetSup + this.pageSizeSup));
  }
  sortRawListSup() {
    if (!this.sorteduSup.active || this.sorteduSup.direction === '') {
      return;
    }
    this.CloneSupplier = this.CloneSupplier.sort((a, b) => {
      const isAsc = this.sorteduSup.direction === 'asc';
      switch (this.sorteduSup.active) {
        case 'name': return this.compare(a.Supplier_Name, b.Supplier_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
  }
  sortDataSup(sort: Sort) {
    this.sorteduSup = sort;
    this.CloneSupplier = this.Supplier.slice();
    if (this.searchSup) {
      this.DSSup();
    }
    if (!sort.active || sort.direction === '' && !this.searchSup) {
      this.CloneSupplier = this.Supplier.slice();
      this.limitListSup();
      return;
    }

    this.CloneSupplier = this.CloneSupplier.sort((a, b) => {
      const isAsc = this.sorteduSup.direction === 'asc';
      switch (this.sorteduSup.active) {
        case 'name': return this.compare(a.Supplier_Name, b.Supplier_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
    this.limitListSup();
  }


  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  viewDetail(raw){
    this.dialog.open(DetailsRawComponent, {
      width: '70%',
      height: 'auto',
      disableClose: true,
      data: raw
    }).afterClosed().subscribe(r=>{
      if(r){
        this.RawDB_controllers.update_Raw(r,this.email);
        this.setup();
      }
    });
  }
  viewDetailPO(po){
    this.dialog.open(DetailPOComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }
  viewOrder(po){
    this.dialog.open(OrdersComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }
  AddRaw(){
    const dialogRefaddPart = this.dialog.open(AddRawComponent, {
      width: '50%',
      height: '80%',
      disableClose: true,
    });
    var newRaw = new RawMaterialInfo();
    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        newRaw = result;
        if (!newRaw.In_Stock){
          newRaw.In_Stock = "0";
        }
        this.RawDB_controllers.add_Raw(newRaw, this.email);
        this.setup();

      }
    });
  }
  OrderDialog(){
    const dialogRefaddPart = this.dialog.open(OrderRawMaterialComponent, {
      width: '80%',
      height: '80%'
    });
    dialogRefaddPart.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }
  ReceiveDialog(){
    const dialogRef = this.dialog.open(ReceivedOrderComponent, {
      width: '80%',
      height: '65%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }
  GenerateQRCode(){
    const dialogRef = this.dialog.open(GenerateQRCodeComponent, {
      width: '80%',
      height: '80%',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }
  AddSupplier(){
    const dialogRef = this.dialog.open(AddSupplierComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.SupplierDB_controller.add_Supplier(result, this.email);
        this.setup();
      }
    });
  }
  ViewSupplier(supplier: Supplier){

    const dialogRef = this.dialog.open(AddSupplierComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: {...supplier}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.SupplierDB_controller.update_Supplier(result, this.email);
        this.setup();
      }
    });
  }

  deletePO(po: PurchaseOrderRaw){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this po? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PORawController.delete_PO(po.PO_No);
        this.setup();
      }
    })
  }
  deleteRaw(raw : RawMaterialInfo){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this raw material? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.RawDB_controllers.delete_Raw(raw.Material_ID, raw.Material_Name, this.email);
        this.setup();
      }
    })
  }


  deleteSup(sup : Supplier){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this supplier? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.SupplierDB_controller.delete_Supplier(sup.Supplier_ID);
        this.setup();
      }
    })
  }

  ManageRawType(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(ManageRawTypeComponent, dialogConfig);
  }

  GenerataOldQRCode(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = this.stocklist.slice();
    this.dialog.open(GenerateOldQRCodeComponent, dialogConfig).afterClosed().subscribe(result=>{
      if(result){
        this.setup();
      }
    });
  }

  GetActivity(value){
    this.rawLength = value;
    this.getRawLog();
  }

  GetActivity2(value){
    this.poLength = value;
    this.getPOLog();
  }

  GetActivity3(value){
    this.crushingLength = value;
    this.getCrushingLog();
  }

  searchLog(){
    this.getRawLog();
    this.getPOLog();
    this.getCrushingLog();

  }

  limitPending(){
    this.clonePendingListPO = this.PendingListPO.slice(this.offsetPending, (this.offsetPending + this.pageSizePending));
  }

  limitCompleted(){
    this.cloneCompleteListPO = this.CompleteListPO.slice(this.offsetCompleted, (this.offsetCompleted + this.pageSizeCompleted));
  }

  paginatorPending(pageEvent: PageEvent) {
    this.pageSizePending = pageEvent.pageSize;
    this.pageIndexPending = pageEvent.pageIndex;
    this.offsetPending = this.pageSizePending * this.pageIndexPending;
    this.sortPending();
    this.limitPending();
  }

  paginatorCompleted(pageEvent:PageEvent){
    this.pageSizeCompleted = pageEvent.pageSize;
    this.pageIndexCompleted = pageEvent.pageIndex;
    this.offsetCompleted = this.pageSizeCompleted * this.pageIndexCompleted;
    this.sortCompleted();
    this.limitCompleted();
  }

  sortPending() {
    if (!this.sorteduPending.active || this.sorteduPending.direction === '') {
      return;
    }
    this.clonePendingListPO = this.PendingListPO.sort((a, b) => {
      const isAsc = this.sorteduPending.direction === 'asc';
      switch (this.sorteduPending.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortCompleted() {
    if (!this.sorteduCompleted.active || this.sorteduCompleted.direction === '') {
      return;
    }
    this.cloneCompleteListPO = this.CompleteListPO.sort((a, b) => {
      const isAsc = this.sorteduCompleted.direction === 'asc';
      switch (this.sorteduCompleted.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortDataPending(sort: Sort) {
    this.sorteduPending = sort;
    this.clonePendingListPO = this.PendingListPO.slice();

    if (!sort.active || sort.direction === '' ) {
      this.clonePendingListPO = this.PendingListPO.slice();
      this.limitPending();
      return;
    }
    this.clonePendingListPO = this.PendingListPO.sort((a, b) => {
      const isAsc = this.sorteduPending.direction === 'asc';
      switch (this.sorteduPending.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
    this.limitPending();
  }

  sortDataCompleted(sort: Sort) {
    this.sorteduCompleted = sort;
    this.cloneCompleteListPO = this.CompleteListPO.slice();

    if (!sort.active || sort.direction === '' ) {
      this.cloneCompleteListPO = this.CompleteListPO.slice();
      this.limitCompleted();
      return;
    }
    this.cloneCompleteListPO = this.CompleteListPO.sort((a, b) => {
      const isAsc = this.sorteduCompleted.direction === 'asc';
      switch (this.sorteduCompleted.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
    this.limitCompleted();
  }


  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    const rawlist = this.RawList.sort((a,b)=>{
      return (a.Raw_Type < b.Raw_Type ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const raw of rawlist) {
      const info ={
        "Raw Type":raw.Raw_Type || "-",
        "Raw Material Name/Size": raw.Material_Name || "-",
        "Stock":raw.In_Stock || 0,
        "Reserved":raw.Quantity_Reserved || 0,
        "Quantity Per Bag":raw.Quantity_PerBag || 0,
        "Material Color":raw.Material_Color || "-",
        "Material Color Ratio":raw.Material_Color_Ratio|| "-",
        "Material Spen":raw.Material_Spen|| "-",
        "Carton Weight":raw.Carton_Weight|| "-",
        "Unit Price":raw.Unit_Price|| "-",
        "Unit": raw.Unit|| "-",
      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'RawMaterial'+new Date().getTime());
    this.spinner.hide();

  }

  machineList: Machine[] = [];
  machineDB_controller: MachineDB_controller = new MachineDB_controller(this.db);

  async initMachine(){
    this.db.database.ref('Machine').once('value', async (DataSnapshot) => {
      this.machineList = await this.machineDB_controller.getMachineListForRawMaterial(DataSnapshot);
      this.machineList = this.machineList.sort((a, b) => {
        return (Number(a.Machine_No.match(/(\d+)/g)[0]) - Number((b.Machine_No.match(/(\d+)/g)[0])));
      })

      this.machineList.forEach((element,index) => {
        element.Schedule_Track = this.getSortedScheduleList(element.Schedule_Track, element.PreviousScheduleTrack);

        if(element.Schedule_Track.length > 0)
          this.getRawMaterialUsed(element.Schedule_Track[0]);
        if(element.Schedule_Track.length > 1)
          this.getRawMaterialUsed(element.Schedule_Track[1]);
      })

    })


  }

  async getRawMaterialUsed(schedule: ScheduleTracker): Promise<void> {
    await this.db.database.ref('Purchase Order').
      child(schedule.Machine_Schedule_PO_No + '/Part List/' + schedule.Machine_Schedule_No + '/Raw Materials Used').on('value', async (snapshot)=>{
        if (snapshot.exists()) {
          schedule.RawMaterialUsed = [];
          snapshot.forEach(element => {
            element.forEach(s => {
              const index = schedule.RawMaterialUsed.findIndex(u => u.MaterialID === s.child('RawMaterialID').val());
              if (index !== -1) {
                schedule.RawMaterialUsed[index].Quantity += parseInt(s.child('Quantity Checkout').val());
              } else {
                const rawMaterialUsed = new RawMaterialUsed;
                rawMaterialUsed.Quantity = s.child('Quantity Checkout').val();
                rawMaterialUsed.MaterialName = s.child('RawMaterialName').val();
                rawMaterialUsed.MaterialID = s.child('RawMaterialID').val();
                schedule.RawMaterialUsed.push(rawMaterialUsed);
              }
            })
          })
        }
      });


    var boxsnapsot = await this.db.database.ref('Purchase Order').
      child(schedule.Machine_Schedule_PO_No + '/Part List/' + schedule.Machine_Schedule_No + '/Box').once('value');
      if (boxsnapsot.exists()) {
        boxsnapsot.forEach(s => {
            let rawN = new RawMaterialNeeded;
            rawN.MaterialID = s.child('ID').val();
            rawN.MaterialName = s.child('Name').val();
            rawN.Quantity = s.child('Quantity Needed').val();
            schedule.RawMaterialNeeded.push(rawN);
        })
      }
  }



 getSortedScheduleList(Schdule_TrackList: ScheduleTracker[], previousSchedules: ScheduleTracker[]): ScheduleTracker[] {
    let SortedScheduleList: ScheduleTracker[] = [];

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Schdule_TrackList[i].Button_Status = false;
        Schdule_TrackList[i].Active_Schedule_Status = false;
        SortedScheduleList.push(Schdule_TrackList[i]);
      }
    }

    if (SortedScheduleList.length > 0) {
      SortedScheduleList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }

    let InProgress_Schedule: ScheduleTracker;
    InProgress_Schedule = this.getInProgressSchedule(Schdule_TrackList)

    let Stopped_Schedule: ScheduleTracker;
    Stopped_Schedule = this.getStoppedSchedule(Schdule_TrackList)

    if (InProgress_Schedule != null) {
      InProgress_Schedule.Button_Status = false;
      InProgress_Schedule.Active_Schedule_Status = false
      SortedScheduleList.unshift(InProgress_Schedule)
    } else if (Stopped_Schedule != null) {
      Stopped_Schedule.Button_Status = false;
      Stopped_Schedule.Active_Schedule_Status = true
      SortedScheduleList.unshift(Stopped_Schedule)

    } else if (SortedScheduleList.length > 0) {
      SortedScheduleList[0].Active_Schedule_Status = true
    }

    return SortedScheduleList;
  }
  getWaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_ScheduleList: ScheduleTracker[] = [];
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Waiting_ScheduleList.push(Schdule_TrackList[i])
      }
    }

    if (Waiting_ScheduleList.length > 0) {
      let waiting_schedule: ScheduleTracker = Waiting_ScheduleList[0];
      for (var j = 1; j < Waiting_ScheduleList.length; j++) {
        if (waiting_schedule.Machine_Schedule_Start_Date > Waiting_ScheduleList[j].Machine_Schedule_Start_Date) {
          waiting_schedule = Waiting_ScheduleList[j]
        }
      }
      return waiting_schedule;
    } else {
      return null;
    }
  }

  getInProgressSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    let InProgress_Schedule: ScheduleTracker;
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")) {

        InProgress_Schedule = Schdule_TrackList[i]
        return InProgress_Schedule
      }
    }
    return null;
  }

  getStoppedSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Stopped_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Stopped")) {
        Stopped_Schedule = Schdule_TrackList[i]
        return Stopped_Schedule
      }
    }
    return null;
  }

  getNeededQuantity(schedule: ScheduleTracker, ID, quantity){

    var qty = parseFloat(quantity);
    schedule.RawMaterialUsed.forEach(e=>{
      if(e.MaterialID === ID)
        qty = qty - e.Quantity;
    })

    if(qty <= 0){
      qty = 0;
    }
    return qty.toFixed(2)

  }

  nextOrBack(machine){
    machine.current = !machine.current;
    var element = machine.Schedule_Track[0];
    machine.Schedule_Track.splice(0, 1);
    machine.Schedule_Track.splice(1, 0, element);
  }

  getColor(quantityNeeded, aftercheckout){
    if(parseFloat(quantityNeeded) === parseFloat(aftercheckout)){
      return "red";
    }
    else if(parseFloat(aftercheckout) === 0){
      return "green"
    }else{
      return "rgb(255 169 0)"
    }
  }

  getTwoDecimal(number){
    return parseFloat(number).toFixed(2);
  }


  getColorPanel(machine:Machine){
    if(machine.Schedule_Track.length === 0){
      return 'off';
    }
    const clone : ScheduleTracker = cloneDeep(machine.Schedule_Track[0]);

    for (const raw of clone.RawMaterialNeeded) {
      for (const check of  clone.RawMaterialUsed) {
        if(check.MaterialID === raw.MaterialID)
        raw.Quantity = raw.Quantity - check.Quantity;
      }
      if(raw.Quantity <=0){
        raw.Quantity = 0
      }
    }

    let flag = true;
    for (const q of clone.RawMaterialNeeded) {
      if(q.Quantity != 0){
        flag = false;
        break;
      }
    }

    if(flag){
      return 'running';
    }else{
      let flag2 = true;
      for (const q of machine.Schedule_Track[0].RawMaterialNeeded) {
        const result2 = clone.RawMaterialNeeded.find(e=>e.Quantity == q.Quantity)
        if(!result2){
          flag2 = false;
          break;
        }
      }
      if(flag2){
        return 'error';
      }else{
        return 'warning';
      }
    }

  }
}
