export class RawMaterialInfo{
  private _Material_ID: string;
  private _Material_Color: string;
  private _Material_Color_Code: string;
  private _Material_Color_Ratio: string;
  private _Material_Name: string = '';
  private _Material_Spen: string;
  private _In_Stock: string;
  private _Unit: string;
  private _Unit_Price: string;
  private _Last_Updated: Date;
  private _Created_Date: Date;
  private _Updated_By: string;
  private _Created_By: string;
  private _Quantity_Reserved: string;
  private _Quantity_PerBag: string;
  private _Raw_Type: string;
  private _Carton_Weight: string;

  supplierCurrency:string;
  public location:string ='';
  public POlocation:string='';

  //part usage
  private _Material_Factor: string;
  private _Part_Material_Name: string;
  private _Customer_Material: string;
  private _SPEN_Material: string;
  private _Customer_RAL: string;
  private _Pigment: string;
  private _M_Batch: string;
  private _Mat_Ratio: string;
  private _Supplier: string;
  quantityNeeded: string;
  batchNeeded: string;
  pigmentNeeded: string;
  ID: string;
  factor: string;
  Name:string;
  CustomerMaterial:string;
  SpenMaterial:string;
  CustomerRal:string;
  pigment:string;
  Batch:string;
  Ratio:string;
  supplier:string;
  supplierID:string;
  check:Boolean;
  pigmentcheck:Boolean;
  batchcheck:Boolean;

  //Part Usage
  get Mat_Ratio(): string {
    return this._Mat_Ratio;
  }
  set Mat_Ratio(value: string) {
    this._Mat_Ratio = value;
  }
  get Part_Material_Name(): string {
    return this._Part_Material_Name;
  }
  set Part_Material_Name(value: string) {
    this._Part_Material_Name = value;
  }
  get Supplier(): string {
    return this._Supplier;
  }
  set Supplier(value: string) {
    this._Supplier = value;
  }

  get SPEN_Material(): string {
    return this._SPEN_Material;
  }
  set SPEN_Material(value: string) {
    this._SPEN_Material = value;
  }

  get Customer_RAL(): string {
    return this._Customer_RAL;
  }
  set Customer_RAL(value: string) {
    this._Customer_RAL = value;
  }
  get Pigment(): string {
    return this._Pigment;
  }
  set Pigment(value: string) {
    this._Pigment = value;
  }

  get M_Batch(): string {
    return this._M_Batch;
  }
  set M_Batch(value: string) {
    this._M_Batch = value;
  }

  get Customer_Material(): string {
    return this._Customer_Material;
  }
  set Customer_Material(value: string) {
    this._Customer_Material = value;
  }
  get Material_Factor(): string {
    return this._Material_Factor;
  }
  set Material_Factor(value: string) {
    this._Material_Factor = value;
  }
  //end

  get Carton_Weight(): string {
    return this._Carton_Weight;
  }
  set Carton_Weight(value: string) {
    this._Carton_Weight = value;
  }

  get Raw_Type(): string {
    return this._Raw_Type;
  }
  set Raw_Type(value: string) {
    this._Raw_Type = value;
  }
  get Quantity_Reserved(): string {
    return this._Quantity_Reserved;
  }
  set Quantity_Reserved(value: string) {
    this._Quantity_Reserved = value;
  }
  get Unit_Price(): string {
    return this._Unit_Price;
  }
  set Unit_Price(value: string) {
    this._Unit_Price = value;
  }

  get Unit(): string {
    return this._Unit;
  }
  set Unit(value: string) {
    this._Unit = value;
  }

  get Material_ID(): string {
    return this._Material_ID;
  }
  set Material_ID(value: string) {
    this._Material_ID = value;
  }
  get Material_Color(): string {
    return this._Material_Color;
  }
  set Material_Color(value: string) {
    this._Material_Color = value;
  }

  get Material_Color_Code(): string {
    return this._Material_Color_Code;
  }
  set Material_Color_Code(value: string) {
    this._Material_Color_Code = value;
  }

  get Material_Color_Ratio(): string {
    return this._Material_Color_Ratio;
  }
  set Material_Color_Ratio(value: string) {
    this._Material_Color_Ratio = value;
  }

  get Material_Name(): string {
    return this._Material_Name;
  }
  set Material_Name(value: string) {
    this._Material_Name = value;
  }

  get Material_Spen(): string {
    return this._Material_Spen;
  }
  set Material_Spen(value: string) {
    this._Material_Spen = value;
  }

  get In_Stock(): string {
    return this._In_Stock;
  }
  set In_Stock(value: string) {
    this._In_Stock = value;
  }
  get Last_Updated(): Date {
    return this._Last_Updated;
  }
  set Last_Updated(value: Date) {
    this._Last_Updated = value;
  }
  get Created_Date(): Date {
    return this._Created_Date;
  }
  set Created_Date(value: Date) {
    this._Created_Date = value;
  }

  get Updated_By(): string {
    return this._Updated_By;
  }
  set Updated_By(value: string) {
    this._Updated_By = value;
  }

  get Created_By(): string {
    return this._Created_By;
  }
  set Created_By(value: string) {
    this._Created_By = value;
  }

  get Quantity_PerBag(): string {
    return this._Quantity_PerBag;
  }
  set Quantity_PerBag(value: string) {
    this._Quantity_PerBag = value;
  }


}

