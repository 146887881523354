import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelHelperService {

  constructor() { }

  static toExportFileName(excelFileName: string): string {

    return `${excelFileName}_export.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};

    XLSX.writeFile(workbook, ExcelHelperService.toExportFileName(excelFileName));
  }

  public export3Sheet(json1, json2,json3, excelFileName: string):void{

    console.log(json1)
    console.log(json2)
    console.log(json3)

    const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json1);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    const worksheet3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json3);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet1, 'Job Info');
    XLSX.utils.book_append_sheet(wb, worksheet2, 'Auto Tubing Records');
    XLSX.utils.book_append_sheet(wb, worksheet3, 'Manual Tubing Records');

    XLSX.writeFile(wb, ExcelHelperService.toExportFileName(excelFileName));
  }
}
