import { DateFormatService } from './../../../Services/Utilities/date-format.service';
import { element } from 'protractor';
import { AngularFireStorage } from '@angular/fire/storage';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import jsPDF from 'jspdf';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-generate-old-label',
  templateUrl: './generate-old-label.component.html',
  styleUrls: ['./generate-old-label.component.css']
})
export class GenerateOldLabelComponent implements OnInit {

  POController: PODB_controller = new PODB_controller(this.db);
  PartDB_controller: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);

  selectedPO: any = [];
  selectedPart = [[]];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrCodes = [];
  detailsPO = [];
  dateFrom: Date;
  dateTo: Date;
  maxDate: Date;
  form: FormGroup;
  Parts: Part[] = []
  matSelectParts : Part[] = []
  searchPart:any
  numberOfLabel = 0;
  email:string;
  constructor(
    private dialogRef: MatDialogRef<GenerateOldLabelComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private angularFireAuth: AngularFireAuth,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private dateService: DateFormatService
  ) {
    this.spinner.show();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
  }



  ngOnInit() {
    this.PartDB_controller.getPartList().then(data => {
      this.Parts = data;
      this.matSelectParts = this.Parts.slice();
      this.spinner.hide();
    });

    const partControl = new FormControl();
    const partfilterControl = new FormControl();

    partfilterControl.valueChanges.subscribe(() => {
      this.findPart(partfilterControl);
    });
    partControl.valueChanges.subscribe(() => {
      if (partControl.value) {
        this.searchPart = partControl.value;
        const part = this.Parts.find(p=>p.Part_No === this.searchPart);

        this.form.get('name').setValue(part.Part_Name)
        this.form.get('cusname').setValue(part.Customer_Name)
        this.form.get('carton').setValue(part.CartonBox.Material_Name)
        this.form.get('cartonQuantity').setValue(part.Box_Quantity)

      }
    });

    this.form = this.fb.group({
      partControl: partControl,
      partfilterControl: partfilterControl,
      quantity: new FormControl('', Validators.required),
      warehouse: new FormControl('', Validators.required),
      rack: new FormControl('', Validators.required),
      name: {value:'None', disabled:true},
      cusname: {value:'None', disabled:true},
      carton: {value:'None', disabled:true},
      cartonQuantity: {value:'None', disabled:true},

    });
  }

  findPart(part){
    if (!this.Parts) { return; }
    const search = part.value;
    this.matSelectParts = this.Parts.filter(p => p.Part_No.toLowerCase().includes(search.toLowerCase()));

  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.qrCodes = [];

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Generate label for this part?';

    const part = this.Parts.find(p=>p.Part_No === this.searchPart);
    const date = new Date();

    const oldLabelQuantity  = this.form.get('quantity').value;
    const warehouseLocation = this.form.get('warehouse').value;
    const rack = this.form.get('rack').value;

    var qty =  this.form.get('quantity').value;

    const time = date.getTime();
    for(let i =1; i<=this.numberOfLabel+1;i++){

      if (qty < part.Box_Quantity) {
        const qr = {
          id:i,
          partDetail:part,
          value:  'OLDSTOCK'+time+'$'+ part.Part_No+'$'+qty
        }
        this.qrCodes.push(qr);
      } else {
        const qr = {
          id:i,
          partDetail:part,
          value:  'OLDSTOCK'+time+'$'+ part.Part_No+'$'+part.Box_Quantity
        }
        this.qrCodes.push(qr);
      }
      qty -= part.Box_Quantity;
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.generateLabel(part);

        const info = {
          message:  part.PO_No+ '- Quantity:' + oldLabelQuantity + " Warehouse :" + warehouseLocation + " Rack :" +rack,
          email: this.email,
          date: new Date()
        };
        const dateFormat = this.dateService.convertDateIntoYearMonthDay(new Date());
        this.firestore.collection('OldStockPart').doc(dateFormat).set({Date: new Date()});
        this.firestore.collection('OldStockPart').doc(dateFormat).collection('Add').add(info);

        this.db.database.ref('Part/' + part.Part_No).once('value').then(datasnap => {
          let quantity = parseFloat(datasnap.child('Stock Quantity').val());
          quantity += parseFloat(oldLabelQuantity);
          this.db.database.ref('Part/'+part.Part_No+'/Stock Quantity').set(quantity);
          this.db.database.ref('Part/'+part.Part_No+'/Stock/'+'OLDSTOCK'+
          time+'@@'+part.Part_No +'@@' + warehouseLocation + '@@' +rack ).set(parseFloat(oldLabelQuantity));
          this.spinner.hide();
        })

        this.qrCodes = [];
      }
    });
  }

  calculate(){
    const quantity = parseFloat(this.form.get('quantity').value);
    const cartonQuantity = parseFloat(this.form.get('cartonQuantity').value);

    this.numberOfLabel = Math.ceil(quantity / cartonQuantity);
    if(isNaN(this.numberOfLabel)){
      this.numberOfLabel = 0;
    }
  }


  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0,0, 125,   125);

    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }


  generateLabel(info: Part): Promise<void> {
    const doc = new jsPDF('portrait','mm','a5');
    var remaining = this.form.get('quantity').value;

    for (let index = 1; index <= this.numberOfLabel; index++) {
      const qrcode = document.getElementById(index.toString());
      const imageData = this.getBase64Image(qrcode.firstChild.firstChild);
      var r = remaining;
      remaining -= info.Box_Quantity;
      doc.setFontSize(12);
      doc.setLineWidth(0.2);
      doc.line(2, 2, 145, 2);    //horizontal line
      doc.line(2, 2, 2, 190);    //vertical line
      doc.line(145, 2, 145, 190);
      doc.line(70, 2, 70, 190);
      doc.setFontType('bold');
      doc.setFontSize(10);
      doc.text('(1) Receiver', 4, 7);
      doc.setFontType('unbold');
      doc.setFontSize(10);
      const text = info.Customer_Name + "\n" + info.Customer_Address;
      const splitTitle = doc.splitTextToSize(text, 60);
      doc.text(splitTitle, 4, 12);
      doc.setFontType('bold');
      doc.text('(2) Product Photo', 72, 7);
      const photo = document.createElement('img');
      photo.src = info.PhotoURL;
      doc.addImage(photo, 'PNG', 90, 7, 30, 30);
      doc.line(2, 39 , 145, 39);
      doc.setFontType('bold');
      doc.text('(3) Invoice / Packing List No.' , 4, 45);
      doc.setFontType('unbold');
      doc.setFontSize(12);
      doc.setFontSize(10);
      doc.setFontType('bold');
      doc.text('(4) Supplier Address', 72, 45);
      doc.setFontType('unbold');
      doc.setFontSize(7);
      doc.setFontType('Tunga');
      doc.text('SPEN INDUSTRIES SDN BHD', 72, 50);
      doc.text('NO 12 & 14 LORONG PERINDUSTRIAN BUKIT MINYAK 5,', 72, 55);
      doc.text('TAMAN PERINDUSTRIAN BUKIT MINYAK,', 72, 60);
      doc.text('14100 BUKIT MERTAJAM, PULAU PINANG, WEST MALAYSIA', 72, 65);
      doc.line(70, 68, 145, 68);
      doc.setFontType('bold');
      doc.setFontSize(10);
      doc.text('(5) Net WT', 72, 72);
      doc.setFontType('unbold');
      doc.setFontSize(9);
      doc.line(91, 68, 91, 78);
      doc.setFontType('bold');
      doc.setFontSize(10);
      doc.text('(6) Gross WT', 92, 72);
      doc.setFontType('unbold');
      doc.setFontSize(9);
      doc.line(120, 68, 120, 78);
      doc.setFontType('bold');
      doc.setFontSize(10);
      doc.text('(7) NO Boxes', 122, 72);
      doc.setFontType('unbold');
      doc.setFontSize(9);
      doc.text( index + ' of ' + this.numberOfLabel, 126, 76);
      doc.line(2, 78 , 145, 78);
      doc.setFontType('bold');
      doc.setFontSize(12);
      doc.text('(8) QR Code', 4, 83);
      doc.addImage(imageData, 'JPG', 10, 85);
      doc.text('(9) Customer Part NO (P)', 72, 83);
      doc.setFontType('bold');
      doc.setFontSize(18);
      const splt = doc.splitTextToSize(info.Part_No, 60);
      doc.text(splt, 75, 100);
      doc.line(2, 120 , 145, 120);
      doc.setFontType('bold');
      doc.setFontSize(12);
      doc.text('(10) Quantity ' , 4, 125);
      doc.setFontType('unbold');
      doc.setFontSize(14);
      if(remaining > 0)
        doc.text(info.Box_Quantity + " PIECES", 5, 135);
      else
        doc.text(r + " PIECES", 5, 135);

      doc.setFontType('bold');
      doc.setFontSize(12);
      doc.text('(11) Description', 72, 125);
      doc.setFontSize(14);
      doc.setFontType('unbold');
      const splitTitle2 = doc.splitTextToSize(info.Part_Name, 60);
      doc.text(splitTitle2, 75, 135);
      doc.line(2, 145 , 145, 145);
      doc.setFontType('bold');
      doc.setFontSize(12);
      doc.text('(12) QC / OPERATOR NAME', 4, 150);
      doc.text('(14) Production Date', 72, 150);
      doc.text('Date', 75, 160);
      doc.text('Month', 87, 160);
      doc.text('Year', 105, 160);
      doc.text('M/C', 120, 160);
      doc.line(75, 165, 130, 165);
      doc.line(75, 165, 75, 180);
      doc.line(88, 165, 88, 180);
      doc.line(100, 165, 100, 180);
      doc.line(118, 165, 118, 180);
      doc.line(130, 165, 130, 180);
      doc.line(75, 180, 130, 180);
      doc.line(2, 190, 145, 190);

      if(index < this.numberOfLabel){
        doc.addPage();
      }
    }
    doc.save(info.Part_No+ 'OldLabel.pdf');
    return null;

  }

}
