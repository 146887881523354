import { DateFormatService } from './../../../Services/Utilities/date-format.service';
import { Currency } from './../../../Shared/Currency';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { InvoiceDB_controller } from '../../../Services/DB_Controller/InvoiceDB_controller';
import { Invoice } from 'src/app/Services/Object_Classes/Invoice/Invoice';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PackingList } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AngularFireStorage } from '@angular/fire/storage';
import { PackingListDB_controller } from '../../../Services/DB_Controller/PackingListDB_controller';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { SelectPOStockComponent } from '../add-packing/select-postock/select-postock.component';
import { InfoComponent } from '../add-packing/info/info.component';
const cloneDeep = require('lodash.clonedeep')
import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-update-packing',
  templateUrl: './update-packing.component.html',
  styleUrls: ['./update-packing.component.css']
})
export class UpdatePackingComponent implements OnInit {
  poNo = '';
  totalPart: any;
  partNumber: any;
  quantity: any;
  po_controller: PODB_controller = new PODB_controller(this.db);
  invoice_controller: InvoiceDB_controller = new InvoiceDB_controller(this.db);
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  matSelectPO: PurchaseOrder[] = [];
  matSelectPart=[];
  addForm: FormGroup;
  clone = [];
  newPO: PurchaseOrder = new PurchaseOrder();
  shippingDate = new Date();
  shipDate = [];
  searchPart: any;
  searchPO: any;
  check = false;
  check2 = false;
  selectedParts: any[] = [];
  newInvoice: Invoice = new Invoice();
  email:string;
  @ViewChild('picker', {static: true}) picker: any;
  temporaryInvoiceNum: string = "";
  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  partsList: Part[] = [];
  // partUnitPrice: any[] = [];
  // partMouldPrice: any[] = [];
  // partRemark: any[] = [];
  linkPackingList: any;
  packing_controller: PackingListDB_controller = new PackingListDB_controller(this.db, this.firestore);
  customerList: Customer[] = [];
  customer_controller: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  selectedCustomerName: string;
  selectedCurrency: string;
  currencyList = Currency;
  selectedSameRowInvoice: Invoice;

  public date: moment.Moment;


  searchCustomer: any;
  cloneCustomer : Customer[] = [];
  matSelectCustomer : Customer[] = [];

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  CompleteClonePO = [];
  disabled = false;
  constructor(
    private dialogRef: MatDialogRef<UpdatePackingComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private storage : AngularFireStorage,
    private firestore: AngularFirestore,
    private dateService: DateFormatService
  ) {

    this.linkPackingList = data.seePacking;
    if(this.linkPackingList.AddedToInvoice || this.linkPackingList.Status === 'Shipped'){
      this.disabled = true;
    }

    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    })

  }

  ngOnInit() {

    this.po_controller.getPOListForPackingList().then(data => {

      data.forEach(data2 => {
        data2.PO_Part_List.forEach(data3 => {
            if(this.matSelectPO.find(m=>m.PO_No === data2.PO_No)){
              return;
            }
            this.matSelectPO.push(data2);
            this.clone = this.matSelectPO.slice();
            this.CompleteClonePO = this.clone.slice();
        });
      });
    });

    this.shippingDate = new Date(this.linkPackingList.Shipping_Date);
    this.selectedCustomerName = this.linkPackingList.Destination;
    this.selectedCurrency = this.linkPackingList.Currency;

    this.setForm();
  }

  setForm(){
    const customerControl = new FormControl();
    const customerfilterControl = new FormControl();
    if(this.linkPackingList.Customer)
      customerControl.setValue(this.linkPackingList.Customer);
      customerfilterControl.valueChanges.subscribe(() => {
      this.findCustomer(customerfilterControl);
    });
    customerControl.valueChanges.subscribe(() => {
      if (customerControl.value) {
        this.searchCustomer = customerControl.value;
        this.addForm.get('destination').setValue(this.cloneCustomer.find(c=>c.Customer_Name === this.searchCustomer).Address);
      }
    });


    this.addForm = this.fb.group({
      name: '',
      customerControl: customerControl,
      customerfilterControl: customerfilterControl,
      shipdate: new Date(this.linkPackingList.PackingList_PO_List[0].PackingList_PO_Part_List[0].Shipping_Date),
      destination: this.linkPackingList.Destination,
      currency: this.linkPackingList.Currency,
      packingLists: this.fb.array([]) ,

    });
  }

  findCustomer(cus){
    if (!this.cloneCustomer) { return; }
    const search = cus.value;
    this.matSelectCustomer = this.cloneCustomer.filter(p => p.Customer_Name.toLowerCase().includes(search.toLowerCase()));

  }


  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Update this packing ? ' + this.linkPackingList.PackingList_No;

    const addPOModel = {
      PackingListName : this.addForm.get('name').value,
      ShipDate: this.addForm.get('shipdate').value,
      parts: [],
    };
    const parts = this.addForm.getRawValue().packingLists;

    let flag = false;
    parts.forEach((element, index) => {
      if (!this.selectedParts[index]){
        console.log(this.selectedParts[index])
        this.toast.error('PO information(' + (index + 1) + ') not completed!', 'Please fill in');
        flag = true;

        return;
      }
      const info = {
        poNumber: element.poControl,
        partNumber: this.selectedParts[index].PO_Part_No,
        poQuantity: this.selectedParts[index].POQuantity,
        productionQuantity: this.selectedParts[index].PO_Part_Qty,
        remainingQuantity: (this.selectedParts[index].PO_Part_Qty - this.selectedParts[index].POQuantity).toFixed(2),
        stocks: this.selectedParts[index].Stocks
      };
      if (!info.poNumber || !info.partNumber ) {
          this.toast.error('PO information(' + (index + 1) + ') not completed!', 'Please fill in');
          flag = true;
        return;
      }
      addPOModel.parts.push(info);
    });

    if(!flag){
      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
        if (confirm) {
          const info = {
            email: this.email,
            packinglist: this.linkPackingList.PackingList_No,
            info: {},
            date: new Date()
          };
          const dateFormat = this.dateService.convertDateIntoYearMonthDay(new Date());
          this.firestore.collection('PackingList').doc(dateFormat).set({Date: new Date()});

          const infos = []
          this.linkPackingList.PackingList_PO_List.forEach(data => {
            infos.push(data.PO_No);

            data.PackingList_PO_Part_List.forEach(async data2=>{
              infos.push(data2.Part_No)
              infos.push(data2.isDeleted?true:false)

              if(data2.isDeleted){
                await this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/').once('value').then(snapshot => {
                  snapshot.forEach(chidlsnapshot => {
                    if (chidlsnapshot.child('Part No').val() === (data2.Part_No)) {
                      this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/' + chidlsnapshot.key + '/Added to packing').set(false);
                      this.db.database.ref('Purchase Order/' + data.PO_No + '/Part List/' + chidlsnapshot.key + '/Take From Stocks').set(null);
                    }
                  })
                })
                if(data2.TakeFromStocks.length > 0){
                  var quantity = 0;
                  let logQuantity = 0;
                  infos.push(logQuantity)
                  data2.TakeFromStocks.forEach(async stock=>{
                    logQuantity += stock.DeductedQuantity

                    await this.db.database.ref('Part/' + data2.Part_No).once('value').then(snapshot => {
                      if(quantity === 0)
                        quantity = snapshot.child('Stock Quantity').val();
                      let reservedQuantity = stock.DeductedQuantity;
                      let stockQuantity = parseFloat(snapshot.child('Stock/' + stock.BoxNumber).val())
                      if(!stockQuantity)
                        stockQuantity = reservedQuantity;
                      else
                        stockQuantity +=  reservedQuantity;
                      quantity += reservedQuantity;
                      this.db.database.ref('Part/' + data2.Part_No + '/Stock Quantity').set(quantity);
                      this.db.database.ref('Part/' + data2.Part_No + '/Stock/'+ stock.BoxNumber ).set(stockQuantity);

                      const d1 = this.dateService.convertDateIntoYearMonthDay(new Date());
                      const info1 ={
                        BeforeQuantity : snapshot.child('Stock Quantity').val(),
                        AfterQuantity : quantity,
                        date: new Date()
                      }
                      this.firestore.collection('StockQuantity').doc(d1).set({Date: new Date()});
                      this.firestore.collection('StockQuantity').doc(d1).collection(data2.Part_No).add(info1);

                    })

                    this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
                    '/' + data2.Part_No + '/Weighting/').once('value').then(datasnap=>{
                      var packagingQty = parseFloat(datasnap.child('MakeToStock').val());
                      var deductQty = packagingQty + stock.DeductedQuantity;
                      this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
                      '/' + data2.Part_No + '/Weighting/MakeToStock').set(deductQty);
                      this.db.database.ref('QC/' + stock.BoxNumber.split('@@')[0] +
                      '/' + data2.Part_No + '/Weighting/Taken by PO/' + data.PO_No).set(null);
                    })

                  })
                }

                this.db.database.ref('PackingList/' + this.linkPackingList.PackingList_No+ '/POList/' +data.PO_No +'/' +data2.Part_No).set(null)

              }
            })
          });
          info.info = infos;
          this.firestore.collection('PackingList').doc(dateFormat).collection('Update').add(info);

          this.packing_controller.updatePackinglist(addPOModel ,this.linkPackingList.PackingList_No, this.email);
          this.dialogRef.close(true);
        }
      })
    }

  }


  updatePart(num, index) {
    this.poNo = num;
    if (this.poNo == '') {
      this.matSelectPO = [];
    }
    this.matSelectPart[index] = null;
    this.po_controller.search_PO_withStatusNotCompleted().then(data => {
      data.forEach(data2 => {
        if (this.poNo !== '') {
          if (this.poNo.match(data2.PO_No) && this.poNo.length === data2.PO_No.length) {
            const data ={
              parts: data2.PO_Part_List,
              PONumber: data2.PO_No,
            }
            this.matSelectPart[index] = data;

          }
        }
      });
    });
  }
  choosingPart= [];

  selectPart(j,i) {
    const samePart = this.choosingPart.find(c=> c===  this.matSelectPart[i].parts[j].ID );
    if(samePart){
      this.toast.warning("Same part number and PO, please choose others", "Warning");
      return;
    }
    const result = parseFloat(this.matSelectPart[i].parts[j].PO_Part_Qty) - parseFloat(this.matSelectPart[i].parts[j].POQuantity) ;
    if(result < 0){
      if( this.matSelectPart[i].parts[j].InStock + result  < 0){
        this.toast.warning("Currently the stock of the selected pack is not enough.", "Warning");
        return;
      }else{
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.height = 'auto';
        dialogConfig.width = '80%';
        const position = {
          top: '5%'
        };
        dialogConfig.position = position;
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          partNo:this.matSelectPart[i].parts[j].PO_Part_No,
          total: result *-1,
        }
        this.dialog.open(SelectPOStockComponent, dialogConfig).afterClosed().subscribe(result => {
          if(result){
            this.matSelectPart[i].parts[j].Stocks = result;
            this.selectedParts[i] = (this.matSelectPart[i].parts[j]);

            const info = this.matSelectPart[i].parts[j].ID;

            var arrayControl = this.addForm.get('packingLists') as FormArray;
            arrayControl.at(i).get('poControl').disable({ onlySelf: true });

            this.matSelectPart[i] = null;
            this.choosingPart.push(info);
          }
          else{
            return;
          }
        });
      }
    }else{
      this.selectedParts[i] = (this.matSelectPart[i].parts[j]);
      const info = this.matSelectPart[i].parts[j].ID;
      var arrayControl = this.addForm.get('packingLists') as FormArray;
      arrayControl.at(i).get('poControl').disable({ onlySelf: true });

      this.matSelectPart[i] = null;
      this.choosingPart.push(info);
    }


  }
  packingLists(): FormArray {
    return this.addForm.get('packingLists') as FormArray;
  }

  newPOs(): FormGroup {
    const pofilterControl = new FormControl();
    const poControl = new FormControl();

    pofilterControl.valueChanges.subscribe(() => {
      this.findPO(pofilterControl);
      if (this.clone) {
        this.check = true;
      }

    });
    poControl.valueChanges.subscribe(() => {
      if (poControl.value) {
        this.searchPO = poControl.value;
        this.check = true;
      }
    });

    return this.fb.group({
      partNumber: '',
      quantity: '',
      dateControl: new Date(),
      poControl,
      pofilterControl,
      poNo: '',
    });
  }

  findPO(po) {
    if (!this.clone) { return; }
    const search = po.value;
    this.matSelectPO = this.clone.filter(p => p.PO_No.toLowerCase().includes(search.toLowerCase()));
  }

  addPO() {
    this.selectedParts.push(null);
    this.matSelectPart.push(null);
    this.packingLists().push(this.newPOs());

    this.matSelectPO = cloneDeep(this.CompleteClonePO)

    this.matSelectPO.forEach(data2 => {
      data2.PO_Part_List = data2.PO_Part_List.filter(p=> p.CustomerName.includes(this.linkPackingList.Customer));
    });

    this.matSelectPO = this.matSelectPO.filter(m=>m.PO_Part_List.length > 0);

    this.clone = this.matSelectPO.slice();
  }

  removeParts(i: number) {
    this.packingLists().removeAt(i);
    this.selectedParts.splice(i, 1);
    this.choosingPart.splice(i, 1);
  }
  display(value){
    if(value> 0)
      return "+"+ value;
    return value;
  }
  view(part){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.data = part.Stocks
    this.dialog.open(InfoComponent, dialogConfig);
  }

  viewPhoto(link){
    window.open(link, '_blank');

  }

  removeExisting(p,i){
    p.PackingList_PO_Part_List[i].isDeleted = true;
  }
  restoreExisting(p,i){
    p.PackingList_PO_Part_List[i].isDeleted = false;

  }

  qrCodes = [];

  print(po, part) {
    this.qrCodes = [];

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Generate label?';
    var boxNeeded;
    var extraBoxNeeded;
    if (part.TakeFromStocks.length > 0) {
      boxNeeded = Math.ceil(part.Part_Quantity / parseInt(part.QuantityPerBox));

      var remaining = part.POQuantity - part.Part_Quantity;
      extraBoxNeeded = Math.ceil(remaining / parseInt(part.QuantityPerBox));
      var counter = 1;
      var qty = parseInt(part.Part_Quantity);
      var remain;

      for (let i = 1; i <= boxNeeded; i++) {

        remain = qty;
        qty -= parseInt(part.QuantityPerBox);
        if (qty > 0) {
          var qr = {
            id: counter,
            po: po.PO_No,
            partDetail: part,
            value: po.PO_No + '$' + part.Part_No + '$' + this.linkPackingList.PackingList_No + '$' + (part.QuantityPerBox)
          }
          this.qrCodes.push(qr);
        } else {
          var qr = {
            id: counter,
            po: po.PO_No,
            partDetail: part,
            value: po.PO_No + '$' + part.Part_No + '$' + this.linkPackingList.PackingList_No + '$' + (remain)
          }
          this.qrCodes.push(qr);
        }

        counter++;
      }

      var r = part.POQuantity - part.Part_Quantity;
      for (let i = 1; i <= extraBoxNeeded + 1; i++) {
        if (i === extraBoxNeeded + 1) {
          const qr = {
            id: counter,
            po: po.PO_No,
            partDetail: part,
            value: po.PO_No + '$' + part.Part_No + '$' + this.linkPackingList.PackingList_No +'$' + (0)
          }
          this.qrCodes.push(qr);

        } else {
          remain = r;
          r -= parseInt(part.QuantityPerBox);
          if (r > 0) {
            var qr = {
              id: counter,
              po: po.PO_No,
              partDetail: part,
              value: po.PO_No + '$' + part.Part_No + '$' + this.linkPackingList.PackingList_No + '$' + (part.QuantityPerBox)
            }
            this.qrCodes.push(qr);
          } else {
            var qr = {
              id: counter,
              po: po.PO_No,
              partDetail: part,
              value: po.PO_No + '$' + part.Part_No + '$' + this.linkPackingList.PackingList_No + '$' + (remain)
            }
            this.qrCodes.push(qr);
          }
        }
        counter++;
      }

    } else {


      let POQuantity;

      if(parseFloat(part.POQuantity) !== 0){
        POQuantity = part.POQuantity / parseFloat(part.QuantityPerBox);
        boxNeeded = Math.ceil(part.POQuantity / parseInt(part.QuantityPerBox));
      }else{
        POQuantity = part.Part_Quantity / parseFloat(part.QuantityPerBox);
        boxNeeded = Math.ceil(part.Part_Quantity / parseInt(part.QuantityPerBox));
      }

      if(Math.ceil(POQuantity) === Math.floor(POQuantity-1)){
        extraBoxNeeded = part.CartonBoxNeeded - boxNeeded + 1;
      }else{
        extraBoxNeeded = boxNeeded;
      }

      var r = 0;
      var qty = parseInt(part.POQuantity);
      for (let i = 1; i <= boxNeeded + 1; i++) {
        if (i === boxNeeded+1) {
          const qr = {
            id: i,
            po: po.PO_No,
            partDetail: part,
            value: po.PO_No + '$' + part.Part_No + '$' + this.linkPackingList.PackingList_No +'$' + (0)
          }
          this.qrCodes.push(qr);
          break;
        }else{
          r = qty;
          qty -= parseInt(part.QuantityPerBox);
          if (qty > 0) {
            var qr = {
              id: i,
              po: po.PO_No,
              partDetail: part,
              value: po.PO_No + '$' + part.Part_No + '$' + this.linkPackingList.PackingList_No + '$' + (part.QuantityPerBox)
            }
            this.qrCodes.push(qr);
          } else {
            var qr = {
              id: i,
              po: po.PO_No,
              partDetail: part,
              value: po.PO_No + '$' + part.Part_No + '$' + this.linkPackingList.PackingList_No + '$' + (r)
            }
            this.qrCodes.push(qr);
          }
        }
      }
    }


    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {

        this.spinner.show();
        try {
         this.generateLabel(part, boxNeeded, extraBoxNeeded, po.PO_No);
         this.spinner.hide();

        } catch (error) {
          this.toast.error(error)
          this.spinner.hide();
        }
        this.qrCodes = [];
      }
    });

  }

  generateLabel(info: any, boxNeeded, extraBoxNeeded, pono): Promise<void> {

    const doc = new jsPDF('portrait', 'mm', 'a5');
    if (info.TakeFromStocks.length > 0) {
      var remaining = info.Part_Quantity;
      var extraLabel = "";

      if(remaining === 0 ){
        remaining = info.POQuantity;
        extraLabel = "(Take From Stock)";
      }

      for (let index = 1; index <= (boxNeeded + extraBoxNeeded) + 1; index++) {
        const qrcode = document.getElementById(index.toString());
        const imageData = this.getBase64Image(qrcode.firstChild.firstChild);

        var r = remaining;
        remaining -= parseInt(info.QuantityPerBox);
        doc.setFontSize(12);
        doc.setLineWidth(0.2);
        doc.line(2, 2, 145, 2);    //horizontal line
        doc.line(2, 2, 2, 205);    //vertical line
        doc.line(145, 2, 145, 205);
        doc.line(70, 2, 70, 205);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(1) Receiver', 4, 7);
        doc.setFontType('unbold');
        doc.setFontSize(10);
        const text = info.CustomerName + "\n" + info.CustomerAddress;
        const splitTitle = doc.splitTextToSize(text, 50);
        doc.text(splitTitle, 4, 12);
        doc.setFontType('bold');
        doc.text('(2) Product Photo', 72, 7);
        const photo = document.createElement('img');
        photo.src = info.Photo;
        doc.addImage(photo, 'PNG', 90, 7, 30, 30);
        doc.line(2, 39, 145, 39);
        doc.setFontType('bold');
        doc.text('(3) Invoice / Packing List No.', 4, 45);
        doc.setFontType('unbold');
        doc.setFontSize(12);
        if (this.linkPackingList.PackingList_No &&  extraLabel !== '(Extra)')
          doc.text("PL NO: "+ this.linkPackingList.PackingList_No, 6, 55);
      // if (info.InvoiceNo)
      //   doc.text(info.InvoiceNo, 6, 65);
      if(pono){
        const d = doc.splitTextToSize("PO: "+pono, 70);
        doc.text(d, 6, 62);
      }
      if(info.Reference){
        const d = doc.splitTextToSize(info.Reference, 70);
        doc.text(d, 14, 69);
      }
      if(info.RevNO){
        const d = doc.splitTextToSize("REV: "+info.RevNO, 70);
        doc.text(d, 6, 76);

      }
      if(info.POS){
        const d = doc.splitTextToSize("POS: "+info.POS, 70);
        doc.text(d, 6, 83);
      }


        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.text('(4) Supplier Address', 72, 45);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.setFontType('Tunga');
        doc.text('SPEN INDUSTRIES SDN BHD', 72, 50);
        doc.text('NO 12 & 14 LORONG PERINDUSTRIAN', 72, 55);
        doc.text('BUKIT MINYAK 5,', 72, 60);
        doc.text('TAMAN PERINDUSTRIAN BUKIT MINYAK,', 72, 65);
        doc.text('14100 BUKIT MERTAJAM,', 72, 70);
        doc.text('PULAU PINANG, WEST MALAYSIA', 72, 75);

        doc.line(70, 80, 145, 80);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(5) Net WT', 72, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(info.Part_Weight + ' GM', 77, 88);
        doc.line(91, 80, 91, 90);
        var crossWeight = (parseFloat(info.Part_Weight) * parseFloat(info.QuantityPerBox)+ parseFloat(info.Carton_Weight))/1000

        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(6) Gross WT', 92, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(crossWeight.toFixed(2) + ' KG', 97, 88);
        doc.line(120, 80, 120, 90);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(7) NO Boxes', 122, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        if (index === (boxNeeded + extraBoxNeeded) + 1) {
          doc.text(1 + ' of ' + 1, 126, 88);
        } else {
          if (extraLabel !== '(Take From Stock)' && boxNeeded > 0)
            doc.text(index + ' of ' + boxNeeded, 126, 88);
          else {
            doc.text(index - boxNeeded + ' of ' + extraBoxNeeded, 126, 88);
          }
        }

        doc.setFontType('bold');
        doc.setFontSize(12);

        doc.line(2, 90, 145, 90);
        doc.text('(8) QR Code', 4, 95);
        doc.addImage(imageData, 'JPG', 10, 97);
        doc.text('(9) Customer Part NO (P)', 72, 95);
        doc.setFontType('bold');
        doc.setFontSize(18);
        const splt = doc.splitTextToSize(info.Part_No, 60);
        doc.text(splt, 75, 105);
        doc.line(2, 132, 145, 132);
        doc.setFontType('bold');
        doc.setFontSize(12);
        if (r === -1) {
          doc.text('(10) Quantity (Extra)', 4, 137);
        } else {
          doc.text('(10) Quantity ' + extraLabel, 4, 137);
        }
        doc.setFontType('unbold');
        doc.setFontSize(14);
        if (remaining > 0)
          doc.text(info.QuantityPerBox + " PIECES", 5, 147);
        else if (r === -1) {

        }
        else
          doc.text(r + " PIECES", 5, 147);



        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(11) Description', 72, 137);
        doc.setFontSize(14);
        doc.setFontType('unbold');
        const splitTitle2 = doc.splitTextToSize(info.Part_Name, 60);
        doc.text(splitTitle2, 75, 147);
        doc.line(2, 157, 145, 157);
        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(12) QC / OPERATOR NAME', 4, 162);
        doc.text('(14) Production Date', 72, 162);
        doc.text('Date', 75, 172);
        doc.text('Month', 87, 172);
        doc.text('Year', 105, 172);
        doc.text('M/C', 120, 172);
        doc.line(75, 177, 130, 177);
        doc.line(75, 177, 75, 192);
        doc.line(88, 177, 88, 192);
        doc.line(100, 177, 100, 192);
        doc.line(118, 177, 118, 192);
        doc.line(130, 177, 130, 192);
        doc.line(75, 192, 130, 192);
        doc.line(2, 205, 145, 205);

        if (index <= (boxNeeded + extraBoxNeeded) + 1) {
          doc.addPage();
          if (extraLabel === '(Take From Stock)') {
            if (remaining <= 0) {
              remaining = -1;
            }
          }
          else if (remaining <= 0) {
            remaining = info.POQuantity - info.Part_Quantity;
            extraLabel = "(Take From Stock)"
          }
        }
      }
      doc.text('Please take from the stock', 10, 10);
      const col = ['PO Number/Old Stock', 'Part Number', 'Quantity'];
      const rows = [];
      info.TakeFromStocks.forEach(e => {
        const row = [e.BoxNumber.split('@@')[0], e.BoxNumber.split('@@')[1], e.DeductedQuantity];
        rows.push(row);
      })
      doc.autoTable(col, rows, { startY: 25 });



    } else {
      let remaining;
      let forecast = false;
      if(parseFloat(info.POQuantity) !== 0){
        remaining = parseFloat(info.POQuantity)
      }else{
        forecast = true;
        remaining = parseFloat(info.Part_Quantity)
      }


      var extraLabel = "";
      for (let index = 1; index <= extraBoxNeeded + 1; index++) {
        const qrcode = document.getElementById(index.toString());
        const imageData = this.getBase64Image(qrcode.firstChild.firstChild);
        let r = remaining;
        remaining -= parseInt(info.QuantityPerBox);
        doc.setFontSize(12);
        doc.setLineWidth(0.2);
        doc.line(2, 2, 145, 2);    //horizontal line
        doc.line(2, 2, 2, 205);    //vertical line
        doc.line(145, 2, 145, 205);
        doc.line(70, 2, 70, 205);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(1) Receiver', 4, 7);
        doc.setFontType('unbold');
        doc.setFontSize(10);
        const text = info.CustomerName + "\n" + info.CustomerAddress;
        const splitTitle = doc.splitTextToSize(text, 60);
        doc.text(splitTitle, 4, 12);
        doc.setFontType('bold');
        doc.text('(2) Product Photo', 72, 7);
        const photo = document.createElement('img');
        photo.src = info.Photo;
        doc.addImage(photo, 'PNG', 90, 7, 30, 30);
        doc.line(2, 39, 145, 39);
        doc.setFontType('bold');
        doc.text('(3) Invoice / Packing List No.' + extraLabel, 4, 45);

        doc.setFontType('unbold');
        doc.setFontSize(12);
        if (this.linkPackingList.PackingList_No &&  extraLabel !== '(Extra)')
          doc.text("PL NO: "+ this.linkPackingList.PackingList_No, 6, 55);

      if(pono){
        const d = doc.splitTextToSize("PO: "+pono, 70);
        doc.text(d, 6, 62);
      }
      if(info.Reference){
        const d = doc.splitTextToSize(info.Reference, 70);
        doc.text(d, 14, 69);
      }
      if(info.RevNO){
        const d = doc.splitTextToSize("REV: "+info.RevNO, 70);
        doc.text(d, 6, 76);
      }
      if(info.POS){
        const d = doc.splitTextToSize("POS: "+info.POS, 70);
        doc.text(d, 6, 83);
      }

        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.text('(4) Supplier Address', 72, 45);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.setFontType('Tunga');
        doc.text('SPEN INDUSTRIES SDN BHD', 72, 50);
        doc.text('NO 12 & 14 LORONG PERINDUSTRIAN', 72, 55);
        doc.text('BUKIT MINYAK 5,', 72, 60);
        doc.text('TAMAN PERINDUSTRIAN BUKIT MINYAK,', 72, 65);
        doc.text('14100 BUKIT MERTAJAM,', 72, 70);
        doc.text('PULAU PINANG, WEST MALAYSIA', 72, 75);

        doc.line(70, 80, 145, 80);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(5) Net WT', 72, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(info.Part_Weight + ' GM', 77, 88);
        doc.line(91, 80, 91, 90);

        var crossWeight = (parseFloat(info.Part_Weight) * parseFloat(info.QuantityPerBox)+ parseFloat(info.Carton_Weight))/1000

        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(6) Gross WT', 92, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(crossWeight.toFixed(2) + ' KG', 97, 88);
        doc.line(120, 80, 120, 90);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(7) NO Boxes', 122, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        if (extraLabel !== '(Extra)')
          doc.text(index + ' of ' + boxNeeded, 126, 88);
        else {
          doc.text(index - boxNeeded + ' of ' + extraBoxNeeded, 126, 88);
        }
        doc.setFontType('bold');
        doc.setFontSize(12);

        doc.line(2, 90, 145, 90);
        doc.text('(8) QR Code', 4, 95);
        doc.addImage(imageData, 'JPG', 10, 97);
        doc.text('(9) Customer Part NO (P)', 72, 95);
        doc.setFontType('bold');
        doc.setFontSize(18);
        const splt = doc.splitTextToSize(info.Part_No, 60);
        doc.text(splt, 75, 105);
        doc.line(2, 132, 145, 132);
        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(10) Quantity ' + extraLabel, 4, 137);
        doc.setFontType('unbold');
        doc.setFontSize(14);
        if (remaining > 0)
          doc.text(info.QuantityPerBox + " PIECES", 5, 147);
        else if (r === -1 || (remaining <0 && extraLabel==="(Extra)")) {

        }
        else
          doc.text(r + " PIECES", 5, 147);



        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(11) Description', 72, 137);
        doc.setFontSize(14);
        doc.setFontType('unbold');
        const splitTitle2 = doc.splitTextToSize(info.Part_Name, 60);
        doc.text(splitTitle2, 75, 147);
        doc.line(2, 157, 145, 157);
        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(12) QC / OPERATOR NAME', 4, 162);
        doc.text('(14) Production Date', 72, 162);
        doc.text('Date', 75, 172);
        doc.text('Month', 87, 172);
        doc.text('Year', 105, 172);
        doc.text('M/C', 120, 172);
        doc.line(75, 177, 130, 177);
        doc.line(75, 177, 75, 192);
        doc.line(88, 177, 88, 192);
        doc.line(100, 177, 100, 192);
        doc.line(118, 177, 118, 192);
        doc.line(130, 177, 130, 192);
        doc.line(75, 192, 130, 192);
        doc.line(2, 205, 145, 205);

        if (index <= extraBoxNeeded) {
          doc.addPage();
          if (extraLabel === '(Extra)') {
            if (remaining < parseInt(info.QuantityPerBox)) {
              remaining = -1;
            }
          }
          else if (remaining <= 0) {
            if(forecast){
              remaining = -1;

            }else{
              remaining = info.Part_Quantity - info.POQuantity;

            }
            extraLabel = "(Extra)"
          }
        }

      }
    }


    doc.save(pono+"_"+info.Part_No + '_Label.pdf');
    return null;

  }

  getBase64Image(img) {
    try {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0,0, 125,   125);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    } catch (error) {
      throw new Error("No Image for part");
    }

  }
}
