export class Weighting {

  private _PO_No: string;
  private _Weight_Part_List: WeightPartList[];
  public Type: string
  get PO_No(): string {
    return this._PO_No;
  }
  set PO_No(value: string) {
    this._PO_No = value;
  }

  get Weight_Part_List(): WeightPartList[] {
    return this._Weight_Part_List;
  }
  set Weight_Part_List(value: WeightPartList[]) {
    this._Weight_Part_List = value;
  }
}

export class WeightPartList {
  private _Part_No: string;
  private _Accept_Qty: number;
  private _Reject_Qty: number;
  private _Reject_Remark: string;
  private _Weight_Date: Date;
  private _WeightingInfo: BoxInfo;
  public RejectedInfos: RejectedInfo[] = [];
  public WorkingArea:string;
  public UpdateBy:string;


  get Part_No(): string {
    return this._Part_No;
  }
  set Part_No(value: string) {
    this._Part_No = value;
  }
  get WeightingInfo(): BoxInfo {
    return this._WeightingInfo;
  }
  set WeightingInfo(value: BoxInfo) {
    this._WeightingInfo = value;
  }

  get Accept_Qty(): number {
    return this._Accept_Qty;
  }
  set Accept_Qty(value: number) {
    this._Accept_Qty = value;
  }

  get Reject_Qty(): number {
    return this._Reject_Qty;
  }
  set Reject_Qty(value: number) {
    this._Reject_Qty = value;
  }

  get Reject_Remark(): string {
    return this._Reject_Remark;
  }
  set Reject_Remark(value: string) {
    this._Reject_Remark = value;
  }

  get Weight_Date(): Date {
    return this._Weight_Date;
  }
  set Weight_Date(value: Date) {
    this._Weight_Date = value;
  }
}

export class RejectedInfo {
  public Code:string;
  public Quantity:number;

}
export class TakenbyPO {
  name:string;
  quantity:number;
}

export class BoxInfo {
  private _Box_No: string;
  private _Updated_Date: Date;
  private _Status: string;
  private _Packaging_Quantity: string;
  public Category: string;
  public TakenbyPO:TakenbyPO[]= [];
  public WarehouseLocation: string;
  public MadeToOrder: string;
  public MakeToStock: string;
  public RackingNo: string;


  get Box_No(): string {
    return this._Box_No;
  }
  set Box_No(value: string) {
    this._Box_No = value;
  }

  set Status(value: string) {
    this._Status = value;
  }
  get Status(): string {
    return this._Status;
  }
  set Packaging_Quantity(value: string) {
    this._Packaging_Quantity = value;
  }

  get Packaging_Quantity(): string {
    return this._Packaging_Quantity;
  }

  get Updated_Date(): Date {
    return this._Updated_Date;
  }
  set Updated_Date(value: Date) {
    this._Updated_Date = value;
  }

}
