import { PartDB_controller } from './../../Services/DB_Controller/PartDB_controller';
import { async } from '@angular/core/testing';
import { RawDB_controller } from './../../Services/DB_Controller/RawDB_controller';
import { RawMaterialInfo } from './../../Services/Object_Classes/RawMaterial/RawMaterial';
import { SupplierDB_controller } from './../../Services/DB_Controller/SupplierDB_controller';
import { Supplier } from './../../Services/Object_Classes/RawMaterial/Supplier';
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { MachineDB_controller } from './../../Services/DB_Controller/MachineDB_controller';
import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import Chart from 'chart.js';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgZone } from '@angular/core';
import { Machine, ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PartServices } from 'src/app/Services/Utilities/part.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  InterruptCodeList = [
    {
      value: 'FB',
      ViewValue: '"Pop" Film Broke',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'WM',
      ViewValue: 'Water Marking',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'FS',
      ViewValue: 'FILM SIZE',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'CD',
      ViewValue: 'Colour Different',
      Color: '#ffcc99',
      Category:"TUBING"
    },

    {
      value: 'TO',
      ViewValue: 'Tubing Others',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'NMP',
      ViewValue: 'No Man Power',
      Color: '#45a8ff',
      Category:"MAN"
    },
    {
      value: 'MO',
      ViewValue: 'Machine Off',
      Color: '#45a8ff',
      Category:"MAN"
    },
    {
      value: 'MAN_OTH',
      ViewValue: 'Man Others',
      Color: '#45a8ff',
      Category:"MAN"
    },
    {
      value: 'COF',
      ViewValue: 'Change of Filter',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
    {
      value: 'CB',
      ViewValue: 'Clean Balancer',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
    {
      value: 'DP',
      ViewValue: 'DPIO Problem',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
    {
      value: 'EO',
      ViewValue: 'Equipment Others',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
  ]
  machineDbControler: MachineDB_controller = new MachineDB_controller(this.db)
  MachineList: Machine[] = [];
  jobOEE: number[] = [];
  monthOEE: number[] = [];
  noJobHours: number[] = [];
  partlist: Part[] = [];
  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private toast: ToastrService,
    private zone: NgZone,
    private partSrv: PartServices,
    private changeDef: ChangeDetectorRef
  ) { }
  ngOnInit() {
    this.setup();
    this.partSrv.currentPart.subscribe(part => this.partlist = part);

  }
  getTime(value) {
    return (new Date(new Date().getTime() + value * 60 * 60000))
  }
  customer: Customer[] = []
  supplier: Supplier[] = []
  rawmaterial: RawMaterialInfo[] = []

  async getPart(schedule: ScheduleTracker): Promise<void> {
    const result = this.partlist.find(e => e.Part_No === schedule.Machine_Schedule_Part_No);
    schedule.OpeningStockPart = result.Stock_Quantity;
    schedule.PartName = result.Part_Name;
    schedule.MouldRefNo = result.Mould_Reference_No;
    schedule.PartCavityNum = result.Number_Cavity.toString();
    if ((schedule.Exp_Qty - schedule.Acc_Qty) <= 0)
      schedule.HourLeft = 0;
    else
      schedule.HourLeft = parseFloat(schedule.PredefinedCycleTime) * ((schedule.Exp_Qty - schedule.Acc_Qty) / parseFloat(schedule.PartCavityNum)) / 3600;

  }


  setup() {
    this.spinner.show();

    this.db.database.ref('Machine').on('value', async (DataSnapshot) => {
      this.MachineList = await this.machineDbControler.getMachineList(DataSnapshot);
      this.MachineList = this.MachineList.sort((a, b) => {
        return (Number(a.Machine_No.match(/(\d+)/g)[0]) - Number((b.Machine_No.match(/(\d+)/g)[0])));
      })

      this.MachineList.forEach(element => {
        let cDate = new Date();
        element.OEE.forEach(data => {
          if (data.Year.match(cDate.getFullYear().toString())) {
            data.Monthly_OEE.forEach(data2 => {
              let monthSub = data2.Month.substring(1, data2.Month.length);
              if (monthSub.match((cDate.getMonth() + 1).toString())) {
                let calcMonthOEE = parseFloat((data2.This_Month_OEE / data2.Total_Jobs).toFixed(2));
                this.monthOEE.push(calcMonthOEE);
                this.noJobHours.push(data2.Total_No_Jobs_Hours);
              }
            });
          }
        });

        element.Schedule_Track = this.getSortedScheduleList(element.Schedule_Track);
        this.getPart(element.Schedule_Track[0]);
        this.getPart(element.Schedule_Track[1]);
        this.changeDef.detectChanges();
        this.changeDef.markForCheck();

      })
      this.changeDef.detectChanges();
      this.changeDef.markForCheck();

      this.spinner.hide();

    })
  }

  getSortedScheduleList(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker[] {
    let SortedScheduleList: ScheduleTracker[] = [];

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Schdule_TrackList[i].Button_Status = false;
        Schdule_TrackList[i].Active_Schedule_Status = false;
        SortedScheduleList.push(Schdule_TrackList[i]);
      }
    }


    if (SortedScheduleList.length > 0) {
      SortedScheduleList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }

    let InProgress_Schedule: ScheduleTracker;
    InProgress_Schedule = this.getInProgressSchedule(Schdule_TrackList)

    let Stopped_Schedule: ScheduleTracker;
    Stopped_Schedule = this.getStoppedSchedule(Schdule_TrackList)

    if (InProgress_Schedule != null) {
      InProgress_Schedule.Button_Status = false;
      InProgress_Schedule.Active_Schedule_Status = false
      SortedScheduleList.unshift(InProgress_Schedule)
    } else if (Stopped_Schedule != null) {
      Stopped_Schedule.Button_Status = false;
      Stopped_Schedule.Active_Schedule_Status = true
      SortedScheduleList.unshift(Stopped_Schedule)

    } else if (SortedScheduleList.length > 0) {
      SortedScheduleList[0].Active_Schedule_Status = true
    }

    let currentDate = new Date();
    let previousJobList: ScheduleTracker = Schdule_TrackList[0];
    if (Schdule_TrackList.length > 0) {
      for (var i = 0; i < Schdule_TrackList.length; i++) {
        if (new Date(Schdule_TrackList[i].DateFormatEnd).getTime() <= currentDate.getTime()) {
          if (new Date(previousJobList.DateFormatEnd).getTime() <= new Date(Schdule_TrackList[i].DateFormatEnd).getTime()) {
            previousJobList = Schdule_TrackList[i];
          }
        }
      }
      this.jobOEE.push(previousJobList.Availability * previousJobList.Performance);
    } else {
      this.jobOEE.push(0);
    }

    return SortedScheduleList;
  }
  getWaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_ScheduleList: ScheduleTracker[] = [];
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Waiting_ScheduleList.push(Schdule_TrackList[i])
      }
    }

    if (Waiting_ScheduleList.length > 0) {
      let waiting_schedule: ScheduleTracker = Waiting_ScheduleList[0];
      for (var j = 1; j < Waiting_ScheduleList.length; j++) {
        if (waiting_schedule.Machine_Schedule_Start_Date > Waiting_ScheduleList[j].Machine_Schedule_Start_Date) {
          waiting_schedule = Waiting_ScheduleList[j]
        }
      }
      return waiting_schedule;
    } else {
      return null;
    }
  }

  getInProgressSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    let InProgress_Schedule: ScheduleTracker;
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")) {

        InProgress_Schedule = Schdule_TrackList[i]
        return InProgress_Schedule
      }
    }
    return null;
  }

  getStoppedSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Stopped_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Stopped")) {
        Stopped_Schedule = Schdule_TrackList[i]
        return Stopped_Schedule
      }
    }
    return null;
  }

  getColor(machine: Machine) {
    let running_schedule: ScheduleTracker = this.machineDbControler.get_CurrentRunningSchedule(machine.Schedule_Track);
    if (running_schedule) {
      if (machine.MachineStatus.match('ON') && machine.Machine_Code.match('-') && running_schedule && running_schedule.Machine_Schedule_Status.match('In Progress')) {
        return 'running';
      }
    } else {
      if (machine.MachineStatus.match('ON') && machine.Machine_Code.match('-')) {
        return 'warning';
      } else if (machine.MachineStatus.match('OFF') && machine.Machine_Code.match('-')) {
        return 'off';
      } else {
        return 'error';
      }
    }
  }

  Nan(value) {
    if (isNaN(value))
      return 0
    else
      return value
  }
  getCode(value) {
    let r =  this.InterruptCodeList.find(i => i.value === value);
    if(r)
      return r.ViewValue;
    else
      return "Others"
  }


  returnNumber(value) {
    return parseInt(value);
  }
}
