import { Schedule } from './../../Services/Object_Classes/Schedule/Schedule';
import { Chart } from 'chart.js';
import { Part } from './../../Services/Object_Classes/Part/Part';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { MachineDB_controller } from '../../Services/DB_Controller/MachineDB_controller';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { ScheduleTracker, Machine } from '../../Services/Object_Classes/Machine/Machine';
import { ModalService } from '../_modal';
import { ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { PartServices } from 'src/app/Services/Utilities/part.service';
import 'chartjs-chart-radial-gauge';
import { OEEHistoryComponent } from './oee-history/oee-history.component';
import { Tubing, TubingInfo } from 'src/app/Services/Object_Classes/Tubing/Tubing';
import { DowntimeDetailsComponent } from '../purchaseOrder/downtime-details/downtime-details.component';
import { JobTubingDetailsComponent } from '../purchaseOrder/job-tubing-details/job-tubing-details.component';
@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements OnInit {

  InterruptCodeList = [
    {
      value: 'FB',
      ViewValue: '"Pop" Film Broke',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'WM',
      ViewValue: 'Water Marking',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'FS',
      ViewValue: 'FILM SIZE',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'CD',
      ViewValue: 'Colour Different',
      Color: '#ffcc99',
      Category:"TUBING"
    },

    {
      value: 'TO',
      ViewValue: 'Tubing Others',
      Color: '#ffcc99',
      Category:"TUBING"
    },
    {
      value: 'NMP',
      ViewValue: 'No Man Power',
      Color: '#45a8ff',
      Category:"MAN"
    },
    {
      value: 'MO',
      ViewValue: 'Machine Off',
      Color: '#45a8ff',
      Category:"MAN"
    },
    {
      value: 'MAN_OTH',
      ViewValue: 'Man Others',
      Color: '#45a8ff',
      Category:"MAN"
    },
    {
      value: 'COF',
      ViewValue: 'Change of Filter',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
    {
      value: 'CB',
      ViewValue: 'Clean Balancer',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
    {
      value: 'DP',
      ViewValue: 'DPIO Problem',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
    {
      value: 'EO',
      ViewValue: 'Equipment Others',
      Color: '#ff829b',
      Category:"EQUIPMENT"
    },
  ]

  machineList: Machine[] = [];
  RunningScheduleDetail: ScheduleTracker = new ScheduleTracker;
  machineDB_controller: MachineDB_controller;
  code: any;
  elem;
  gaugelist = [];
  isFetching = true;

  codeStyleList = [{
    label: 'Running',
    color: 'rgba(0,128,0,1)'
  }, {
    label: 'SM',
    color: 'rgba(139,0,0,1)'
  }, {
    label: 'MP',
    color: 'rgba(0,0,139,1)'
  }, {
    label: 'NL',
    color: 'rgba(72,61,139,1)'
  }, {
    label: 'MT',
    color: 'rgba(128,0,128,1)'
  }, {
    label: 'WP',
    color: 'rgba(139,69,19,1)'
  }, {
    label: 'SMD',
    color: 'rgba(112,128,144,1)'
  }, {
    label: 'NM',
    color: 'rgba(255,255,0,1)'
  }, {
    label: 'MP',
    color: 'rgba(255,140,0，1)'
  }, {
    label: 'MH',
    color: 'rgba(205,92,92,1)'
  }, {
    label: 'AMSU',
    color: 'rgba(47,79,79,1)'
  }, {
    label: 'AMA',
    color: 'rgba(188,143,143,1)'
  }, {
    label: 'Left',
    color: 'rgba(0,0,0,0.8)'
  },];
  previousjobOEE = [];
  // monthOEE: number[] = [];
  // noJobHours: number[] = [];

  constructor(private db: AngularFireDatabase,
    private zone: NgZone,
    private modalService: ModalService,
    private toast: ToastrService,
    private ref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private partSrv: PartServices,
    private firestore: AngularFirestore,
    private dateService: DateFormatService,
    private dialog: MatDialog) {
    this.partSrv.currentPart.subscribe(part => this.partlist = part);

    this.machineDB_controller = new MachineDB_controller(db);
  }

  OEEView = true;
  OEEChart: Chart;
  ngOnInit() {
    this.elem = document.getElementById('full');
    this.setup();
    setTimeout(() => {
      this.isFetching = false;
    }, 3000);
  }

  getTime(value) {
    return (new Date(new Date().getTime() + value * 60 * 60000))
  }

  getCode(value) {
    let r =  this.InterruptCodeList.find(i => i.value === value);
    if(r)
      return r.ViewValue;
    else
      return "Others"
  }

  async getScheduleTubingInfo(PO_No: string, Part_No: string, schedule: ScheduleTracker): Promise<Tubing> {
    let tubing = new Tubing();
    var snapshot = await this.db.database.ref('ManualTubing/' + PO_No + '/' + Part_No).once('value');

    if (snapshot.exists()) {
      tubing.PartNumber = snapshot.key;
      tubing.PONumber = PO_No;
      tubing.ProductionQuantity = snapshot.child('ProductionQty').val();
      tubing.CreatedDate = snapshot.child('CreatedDate').val();
      tubing.UpdatedDate = snapshot.child('UpdatedDate').val();
      tubing.UpdatedBy = snapshot.child('UpdatedBy').val();
      let total = 0;

      snapshot.child('Tubings').forEach((childSnapshot2) => {
        let tubingInfo = new TubingInfo;
        tubingInfo.TubingAmount = parseFloat(childSnapshot2.val());
        tubingInfo.TimeStamp = childSnapshot2.key;
        tubing.TubingInfos.push(tubingInfo);
        total += parseInt(childSnapshot2.val());
      });

      if(tubing.TubingInfos.length > 0){
        tubing.TubingInfos.sort((a,b)=>{
          return new Date(a.TimeStamp).getTime() > new Date(b.TimeStamp).getTime()? -1 : new Date(a.TimeStamp).getTime() < new Date(b.TimeStamp).getTime() ? 1 :0
        });

        for(var i=0; i<tubing.TubingInfos.length-1; i++){
          tubing.TubingInfos[i].TubingDifferent = new Date(tubing.TubingInfos[i].TimeStamp).getTime() - new Date(tubing.TubingInfos[i+1].TimeStamp).getTime();
          tubing.TubingInfos[i].TubingDifferent = tubing.TubingInfos[i].TubingDifferent / 3600000;
        }

        tubing.TubingInfos[tubing.TubingInfos.length-1].TubingDifferent = 0;
      }

      schedule.Machine_Progress = isFinite(total/schedule.Exp_Qty)?(total/schedule.Exp_Qty*100):0;

      if(schedule.Machine_Progress > 100){
        schedule.Machine_Progress = 100
        schedule.HourLeft = 0;
      }
    }

    return tubing;
  }

  async getScheduleAutoTubingInfo(PO_No: string, Part_No: string): Promise<Tubing> {
    let tubing = new Tubing();
    var snapshot = await this.db.database.ref('AutoTubing/' + PO_No + '/' + Part_No).once('value');

    if (snapshot.exists()) {
      tubing.PartNumber = snapshot.key;
      tubing.PONumber = PO_No;
      tubing.ProductionQuantity = snapshot.child('ProductionQty').val();
      tubing.CreatedDate = snapshot.child('CreatedDate').val();
      tubing.UpdatedDate = snapshot.child('UpdatedDate').val();
      tubing.UpdatedBy = snapshot.child('UpdatedBy').val();

      snapshot.child('Tubings').forEach((childSnapshot2) => {
        let tubingInfo = new TubingInfo;
        tubingInfo.TubingAmount = parseFloat(childSnapshot2.val());
        tubingInfo.TimeStamp = childSnapshot2.key;
        tubing.TubingInfos.push(tubingInfo);
      });

      if(tubing.TubingInfos.length > 0){
        tubing.TubingInfos.sort((a,b)=>{
          return Number(a.TimeStamp) > Number(b.TimeStamp) ? -1 : Number(a.TimeStamp) < Number(b.TimeStamp) ? 1 :0
        });

        for(var i=0; i<tubing.TubingInfos.length-1; i++){
          tubing.TubingInfos[i].TubingDifferent = parseFloat(tubing.TubingInfos[i].TimeStamp) - parseFloat(tubing.TubingInfos[i+1].TimeStamp);
          tubing.TubingInfos[i].TubingDifferent = tubing.TubingInfos[i].TubingDifferent / 3600000;
        }

        tubing.TubingInfos[tubing.TubingInfos.length-1].TubingDifferent = 0;
      }
    }

    return tubing;
  }

  getMachineNo(machineNo){
    var no = machineNo.match(/(\d+)/);
    return (parseFloat(no[0]) - 1);
  }
  productionPerformance = [];
  getProductionPerformance() {
    const dateList = [];
    const date = new Date();
    const currentDate = this.dateService.convertDateIntoYearMonthDay(new Date());
    date.setHours(date.getHours() - 12);
    const dateFormat = this.dateService.convertDateIntoYearMonthDay(date);
    const i = {
      label: currentDate,
      value: date,
    }
    dateList.push(i);
    if (currentDate !== dateFormat) {
      const i = {
        label: dateFormat,
        value: date,
      }
      dateList.unshift(i)
    }
    for (const machine of this.machineList) {

      for (const date of dateList) {
        const d = firestore.Timestamp.fromDate(date.value);
        this.firestore.collection('MachinePerformanceLog').
          doc(machine.Machine_No).
          collection(date.label, ref => ref.where('date', '>=', d).
            orderBy('date', 'asc')).snapshotChanges().subscribe(listner => {
              var no = machine.Machine_No.match(/(\d+)/);
              this.productionPerformance[parseFloat(no[0]) - 1] = [];
              listner.forEach(e => {
                const info = {
                  from: new Date(e.payload.doc.data().date.toDate()),
                  to: new Date(new Date().setHours(new Date().getHours() - 24)),
                  code: e.payload.doc.data().code,
                  status: e.payload.doc.data().status,
                  machine: no[0],
                }
                if (this.productionPerformance[parseFloat(no[0]) - 1].length > 0) {
                  const temp = this.productionPerformance[parseFloat(no[0]) - 1][this.productionPerformance[parseFloat(no[0]) - 1].length - 1]

                  if (temp.to.getTime() <= info.from.getTime()) {
                    temp.to = info.from;
                    this.productionPerformance[parseFloat(no[0]) - 1][this.productionPerformance[parseFloat(no[0]) - 1].length - 1] = temp;

                    if (temp.code !== info.code || temp.status !== info.status) {
                      this.productionPerformance[parseFloat(no[0]) - 1].push(info);
                    }
                  }
                } else {
                  this.productionPerformance[parseFloat(no[0]) - 1].push(info);

                }
                console.log(this.productionPerformance)
              })
            })
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }


  async getPart(schedule: ScheduleTracker): Promise<void> {
    const result = this.partlist.find(e => e.Part_No === schedule.Machine_Schedule_Part_No);
    schedule.OpeningStockPart = result.Stock_Quantity;
    schedule.PartName = result.Part_Name;
    schedule.MouldRefNo = result.Mould_Reference_No;
    schedule.PartCavityNum = result.Number_Cavity.toString();
    if ((schedule.Exp_Qty - schedule.Acc_Qty) <= 0)
      schedule.HourLeft = 0;
    else
      schedule.HourLeft = parseFloat(schedule.PredefinedCycleTime) * ((schedule.Exp_Qty - schedule.Acc_Qty) / parseFloat(schedule.PartCavityNum)) / 3600;

  }
  partlist: Part[] = [];
  subsribeService: any;
  setup() {
    this.spinner.show();
    this.productionPerformance = [];

    this.db.database.ref('Machine').on('value', async (DataSnapshot) => {
      this.machineList = await this.machineDB_controller.getMachineListOEE(DataSnapshot);
      this.machineList = this.machineList.sort((a, b) => {
        return (Number(a.Machine_No.match(/(\d+)/g)[0]) - Number((b.Machine_No.match(/(\d+)/g)[0])));
      })

      for (const element of this.machineList) {
        this.productionPerformance.push([]);
        element.AverageP = element.PreviousScheduleTrack.map(a => a.Performance).reduce((a, b) => { return a + b }, 0) / element.PreviousScheduleTrack.length || 0;
        element.AverageA = element.PreviousScheduleTrack.map(a => a.Availability).reduce((a, b) => { return a + b }, 0) / element.PreviousScheduleTrack.length || 0;
        element.AverageQ = element.PreviousScheduleTrack.map(a => a.Effectiveness).reduce((a, b) => { return a + b }, 0) / element.PreviousScheduleTrack.length || 0;
        element.AverageOEE = (element.AverageP * (1 - element.AverageQ)* element.AverageA) * 100;
        element.AverageP = element.AverageP * 100;
        element.AverageA = element.AverageA * 100;
        element.AverageQ = 100 - (element.AverageQ * 100);

        this.db.database.ref('Machine').child(element.Machine_No).update({
          overallOEE: element.AverageOEE,
          overallPerformance: element.AverageP,
          overallAvailability: element.AverageA,
          overallQuality: element.AverageQ,
        })

        element.Schedule_Track = this.getSortedScheduleList(element.Schedule_Track, element.PreviousScheduleTrack);

        if (element.Schedule_Track.length > 0) {
          this.getPart(element.Schedule_Track[0]);
          element.Schedule_Track[0].tubingInfo = await this.getScheduleTubingInfo(element.Schedule_Track[0].Machine_Schedule_PO_No, element.Schedule_Track[0].Machine_Schedule_Part_No, element.Schedule_Track[0]);
          element.Schedule_Track[0].autotubingInfo = await this.getScheduleAutoTubingInfo(element.Schedule_Track[0].Machine_Schedule_PO_No, element.Schedule_Track[0].Machine_Schedule_Part_No);
        }
      }

      if (this.OEEView) {
        this.subsribeService = setInterval(() => { this.setup() }, 1800000)
        setTimeout(() => {
          this.initializeChart();
        }, 500);
      }



      this.getProductionPerformance();
      // this.ref.detectChanges();
      // this.ref.markForCheck();
      this.spinner.hide();

    })

  }

  getSortedScheduleList(Schdule_TrackList: ScheduleTracker[], previousSchedules: ScheduleTracker[]): ScheduleTracker[] {
    let SortedScheduleList: ScheduleTracker[] = [];

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Schdule_TrackList[i].Button_Status = false;
        Schdule_TrackList[i].Active_Schedule_Status = false;
        SortedScheduleList.push(Schdule_TrackList[i]);
      }
    }

    if (SortedScheduleList.length > 0) {
      SortedScheduleList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }


    let InProgress_Schedule: ScheduleTracker;
    InProgress_Schedule = this.getInProgressSchedule(Schdule_TrackList)

    let Stopped_Schedule: ScheduleTracker;
    Stopped_Schedule = this.getStoppedSchedule(Schdule_TrackList)

    if (InProgress_Schedule != null) {
      InProgress_Schedule.Button_Status = false;
      InProgress_Schedule.Active_Schedule_Status = false
      SortedScheduleList.unshift(InProgress_Schedule)
    } else if (Stopped_Schedule != null) {
      Stopped_Schedule.Button_Status = false;
      Stopped_Schedule.Active_Schedule_Status = true
      SortedScheduleList.unshift(Stopped_Schedule)

    } else if (SortedScheduleList.length > 0) {
      SortedScheduleList[0].Active_Schedule_Status = true
    }

    let currentJob = null;
    if (SortedScheduleList.length > 0) {
      currentJob = SortedScheduleList[0];
    }
    // let job = null;
    // if (previousSchedules.length > 0) {
    //   previousSchedules.sort((a, b) => b.Machine_Schedule_End_Date.localeCompare(a.Machine_Schedule_End_Date));
    //   for (const previous of previousSchedules) {
    //     if (currentJob) {
    //       if (previous.DateFormatEnd.getTime() < currentJob.DateFormatEnd.getTime() && previous.Machine_Schedule_Status == "Done") {
    //         job = previousSchedules[i];
    //         break;

    //       }
    //     } else {
    //       if (previous.DateFormatEnd.getTime() < new Date().getTime() && previous.Machine_Schedule_Status == "Done") {
    //         job = previousSchedules[i];
    //         break;
    //       }
    //     }

    //   }
    // }
    // if (job) {
    //   const info ={
    //     PO: job.Machine_Schedule_PO_No,
    //     Availability:job.Availability * 100,
    //     Performance:job.Performance * 100,
    //     OEE:job.Availability * job.Performance * 100
    //   }
    //   this.previousjobOEE.push(info);
    // }
    // else {
    //   const info ={
    //     PO: null,
    //     Availability:0,
    //     Performance:0,
    //     OEE:0
    //   }
    //   this.previousjobOEE.push(info);
    // }

    return SortedScheduleList;
  }
  getWaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_ScheduleList: ScheduleTracker[] = [];
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Waiting_ScheduleList.push(Schdule_TrackList[i])
      }
    }

    if (Waiting_ScheduleList.length > 0) {
      let waiting_schedule: ScheduleTracker = Waiting_ScheduleList[0];
      for (var j = 1; j < Waiting_ScheduleList.length; j++) {
        if (waiting_schedule.Machine_Schedule_Start_Date > Waiting_ScheduleList[j].Machine_Schedule_Start_Date) {
          waiting_schedule = Waiting_ScheduleList[j]
        }
      }
      return waiting_schedule;
    } else {
      return null;
    }
  }

  getInProgressSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    let InProgress_Schedule: ScheduleTracker;
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")) {

        InProgress_Schedule = Schdule_TrackList[i]
        return InProgress_Schedule
      }
    }
    return null;
  }

  getStoppedSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Stopped_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Stopped")) {
        Stopped_Schedule = Schdule_TrackList[i]
        return Stopped_Schedule
      }
    }
    return null;
  }


  getColor(machine: Machine) {
    let running_schedule: ScheduleTracker = this.machineDB_controller.get_CurrentRunningSchedule(machine.Schedule_Track);
    if (machine.MachineStatus.match('OFF'))
      return 'off';
    if (running_schedule) {
      if (machine.MachineStatus.match('ON') && machine.Machine_Code.match('-') && running_schedule && running_schedule.Machine_Schedule_Status.match('In Progress')) {
        return 'running';
      }
    } else {
      if (machine.MachineStatus.match('ON') && machine.Machine_Code.match('-')) {
        return 'warning';
      } else if (machine.MachineStatus.match('OFF') && machine.Machine_Code.match('-')) {
        return 'off';
      } else {
        return 'error';
      }
    }
  }

  Nan(value) {
    if (isNaN(value))
      return 0
    else
      return value
  }
  viewOEEHistory(machine: Machine) {

    let allSchedule = machine.Schedule_Track.concat(machine.PreviousScheduleTrack);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '80%';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      machineNumber: machine.Machine_No,
      allSchedule
    };
    this.dialog.open(OEEHistoryComponent, dialogConfig).afterClosed().subscribe(result => {
    });
  }
  getStatus(value) {
    if (value === 'ON')
      return 'On-Color'
    else
      return 'Off-Color'
  }
  returnNumber(value) {
    return parseInt(value);
  }

  CutString(value: string) {
    return value.length > 14 ? '<br>' : ''
  }

  initializeChart() {
    this.spinner.show();
    this.db.database.ref('Machine').off('value');
    let index = 0;
    for (const machine of this.machineList) {
      let color = machine.AverageOEE > 66 ? "#5fe2a0" : machine.AverageOEE > 33 ? "#ffed06" : "#f53c56"
      new Chart('oeeChart' + index, {
        type: 'radialGauge',
        data: {
          labels: ["OEE"],
          datasets: [{
            backgroundColor: [color],
            borderWidth: 0,
            data: [machine.AverageOEE.toFixed(2)],
          }]
        },
        options: {
          maintainAspectRatio: false,
          // The percentage of the chart that is the center area
          centerPercentage: 80,
          domain: [0, machine.AverageOEE > 100 ? machine.AverageOEE : 100],
          // center value options
          centerArea: {
            // whether to display the center text value
            displayText: true,
            // font for the center text
            fontFamily: null,
            // color of the center text
            fontColor: null,
            // the size of the center text
            fontSize: null,
            // padding around the center area
            padding: 4,
            // an image to use for the center background
            backgroundImage: null,
            // a color to use for the center background
            backgroundColor: null,
            // the text to display in the center
            // this could be a string or a callback that returns a string
            // if a callback is provided it will be called with (value, options)
            text: function (value, option) {
              return value + "%"
            },
            // the text to display beneath the text specified above
            subText: "OEE",
          }
        }
      })
      index++;
    }
    this.spinner.hide();
  }

  openOEE() {
    this.OEEView = !this.OEEView
    if (this.OEEView)
      setTimeout(() => {
        this.initializeChart();
      }, 500);
    else {
      this.setup();
      clearInterval(this.subsribeService)
    }
  }

  getOEEColor(value) {
    if (value > 66) {
      return "#5fe2a0";
    } else if (value > 33) {
      return "#ffed06"
    } else {
      return "#f53c56"
    }
  }
  calculateTubing(tubing: Tubing) {
    return tubing.TubingInfos.map(a => a.TubingAmount).reduce((a:any, b:any) => { return parseFloat(a) + parseFloat(b) }, 0).toFixed(2)
  }

  viewTubing(job: ScheduleTracker) {
    this.dialog.open(JobTubingDetailsComponent, {
      width: '80%',
      height: 'auto',
      data: job,
      disableClose: true
    });
  }


}
