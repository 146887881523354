import { DateFormatService } from './../Utilities/date-format.service';
import { RawMaterialOrder, PoInRawMaterial, ReceivedDetails } from './../Object_Classes/RawMaterial/PORawMaterial';
import { AngularFireDatabase } from '@angular/fire/database';
import { PurchaseOrderRaw } from '../Object_Classes/RawMaterial/PORawMaterial';
import { AngularFirestore } from '@angular/fire/firestore';

export class PORawDB_controller {
  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }
  private dateFormat = new DateFormatService();
  /************** Purchase Order ****************/
  async getPORawMaterialList(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    var snapshot = await this.db.database.ref('PO RawMaterial').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let PO_item = new PurchaseOrderRaw();
        PO_item.PO_No = childSnapshot.key;
        PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
        PO_item.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());
        PO_item.Created_By = childSnapshot.child('_Created_By').val();
        PO_item.Updated_By = childSnapshot.child('_Updated_By').val();

        PO_item.Status = childSnapshot.child('_Status').val();
        var rawMaterialOrders: RawMaterialOrder[] = [];
        childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
          let raw = new RawMaterialOrder();
          var poInRawMaterials: PoInRawMaterial[] = [];
          var received: ReceivedDetails[] = [];

          childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
            let po = new PoInRawMaterial();
            po.Po_No = childSnapshot3.key;
            po.Part_No = childSnapshot3.child('Part No').val();
            po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
            po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
            po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
            poInRawMaterials.push(po);
          })

          childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
            let re = new ReceivedDetails();
            re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
            re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
            re.Packets = childSnapshot3.child('_Packets').val();
            re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
            re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
            re.Box_Number = childSnapshot3.child('_Box_Number').val();
            re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
            re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
            re.ImageURL = childSnapshot3.child('_ImageURL').val();

            received.push(re);
          })
          raw.PoInRawMaterialList = poInRawMaterials;
          raw.ReceivedDetailsList = received;
          raw.PoInRawMaterialList = poInRawMaterials;
          raw.PO_Order_ID = childSnapshot2.key;
          raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
          raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
          raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
          raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
          raw.Supplier = childSnapshot2.child('_Supplier').val();
          raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
          raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Unit = childSnapshot2.child('_Unit').val();
          raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
          raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
          raw.Amount = childSnapshot2.child('_Amount').val();


          rawMaterialOrders.push(raw);
        });
        PO_item.PO_RawMaterials = rawMaterialOrders;
        PO_list.push(PO_item);
      });
    }
    return PO_list;
  }

  add_PO(_newPO: any, email: string) {
    const updates = {};
    updates[_newPO.POName + '/_Created_Date'] = new Date();
    updates[_newPO.POName + '/_Updated_Date'] = new Date();
    updates[_newPO.POName + '/_Created_By'] = email;
    updates[_newPO.POName + '/_Updated_By'] = email;
    updates[_newPO.POName + '/_Status'] = 'Pending';

    let materialName = '';
    _newPO.raws.forEach((data,index) => {
      if(index=== _newPO.raws.length-1)
        materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity +'</b>';
      else
        materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity + '</b>\n';
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_ID'] = data.materialID;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Name'] = data.materialName;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Quantity_PerBag'] = data.quantity_perBag;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Qty'] = data.quantity;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Status'] = data.status;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Supplier'] = data.supplier.Supplier_ID;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Delivery_Date'] = data.deliveryDate;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Unit'] = data.unit;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Unit_Price'] = data.unitPrice;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Amount'] = data.amount;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Received_Quantity'] = "0";
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Deducted_Quantity'] = "0";
    });
    this.db.database.ref('PO RawMaterial/').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _newPO.POName + ' has been added by the <b>' + email + '</b>\n' + materialName,
      date: new Date()
    };
    this.firestore.collection('PORawMaterialLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('PORawMaterialLog').doc(dateFormat).collection('PO').add(info);
  }

  update_PO(PO: any, email: string) {
    const updates = {};
    updates[PO.selectedPO.PO_No + '/_Updated_Date'] = new Date();
    updates[PO.selectedPO.PO_No + '/_Updated_By'] = email;
    updates[PO.selectedPO.PO_No + '/_Status'] = PO.status;
    updates[PO.selectedPO.PO_No + '/_Completed_Date'] = PO.Completed_Date;

    PO.selectedRaws.forEach(async (data, index) => {
      updates[PO.selectedPO.PO_No + '/RawMaterials/' + data.PO_Order_ID + '/_PO_RawMaterial_Status'] = PO.detailsRaws[index].status;
      await this.db.database.ref('RawMaterial/' + data.PO_RawMaterial_ID).once('value').then(datasnap => {
        // tslint:disable-next-line: radix
        let quantity = parseFloat(datasnap.child('_In_Stock').val());
        const date = new Date();
        // tslint:disable-next-line: radix
        quantity += parseFloat(PO.detailsRaws[index].receivedQuantity);
        let sentence = 'Material Name: <b>' + datasnap.child('_Material_Name').val() + '</b>\nQuantity: <b>' + PO.detailsRaws[index].receivedQuantity+'</b>';
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' + data.PO_Order_ID + '/_Received_Quantity').set(PO.detailsRaws[index].receivedQuantity);

        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Quantity_PerBags').set(PO.detailsRaws[index].quantity);
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Received_Quantity').set(PO.detailsRaws[index].amount);
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Received_Date').set(PO.detailsRaws[index].receivedDate.toISOString());
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Rack_Number').set(PO.detailsRaws[index].rack);
          this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_ImageURL').set(PO.detailsRaws[index].file);
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Packets').set(PO.detailsRaws[index].packets);
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Invoice_Number').set(PO.detailsRaws[index].invoice);
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Lot_Number').set(PO.detailsRaws[index].lotNumber);
        // this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
        //   data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Box_Number').set(PO.detailsRaws[index].box);

        this.db.database.ref('RawMaterial/' + data.PO_RawMaterial_ID + '/_In_Stock').set(quantity.toFixed(2));
        this.db.database.ref('RawMaterial/' + data.PO_RawMaterial_ID + '/_Updated_By').set(email);
        this.db.database.ref('RawMaterial/' + data.PO_RawMaterial_ID + '/_Last_Updated').set(date.toISOString());
        const info = {
          message: PO.selectedPO.PO_No + ' has been updated by the <b>' + email + '</b>\n' + sentence,
          date: new Date()
        };
        this.firestore.collection('PORawMaterialLog').doc(dateFormat).set({ Date: new Date() });
        this.firestore.collection('PORawMaterialLog').doc(dateFormat).collection('PO').add(info);
      });
    });
    this.db.database.ref('PO RawMaterial/').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());

  }

  async search_PO(PO_No: string): Promise<PurchaseOrderRaw> {

    let searched_PO = new PurchaseOrderRaw();
    const snapshot = await this.db.database.ref('PO RawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let get_PO_No = childSnapshot.key;
        if (PO_No.match(get_PO_No)) {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());

          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];
            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();

              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;

            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();

            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
        }
      });
    }

    return searched_PO;
  }



  async search_Partial_PO(PO_No: string): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    const snapshot = await this.db.database.ref('PO RawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrderRaw();
        let get_PO_No = childSnapshot.key;
        if (get_PO_No.includes(PO_No)) {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());
          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];

            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;

            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();

          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
            raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();



            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(searched_PO);
        }
      });
    }
    return PO_list;
  }


  async search_PO_withStatusPending(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    const snapshot = await this.db.database.ref('PO RawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrderRaw();
        let status = childSnapshot.child('_Status').val();
        if (status === 'Pending') {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());

          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];

            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;

            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
            raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();


            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(searched_PO);
        }
      });
    }
    return PO_list;
  }

  async search_PO_withStatusCompleted(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    const snapshot = await this.db.database.ref('PO RawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrderRaw();
        let status = childSnapshot.child('_Status').val();
        if (status === 'Completed') {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());

          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];
            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;
            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
            raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();
            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(searched_PO);
        }
      });
    }
    return PO_list;
  }

  delete_PO(PO_No: string) {
    this.db.database.ref('/PO RawMaterial/' + PO_No).set(null);
  }

}
