import { TakeFromStock } from './../PackingList/PackingList';
export class PurchaseOrder {

  private _PO_No: string;
  private _PO_Part_List: PartTracker[];
  private _Created_By : string;
  private _Created_Date : Date;
  private _Updated_By : string;
  private _Updated_Date : Date;

  get PO_No(): string {
    return this._PO_No;
  }
  set PO_No(value: string) {
    this._PO_No = value;
  }

  get PO_Part_List(): PartTracker[] {
    return this._PO_Part_List;
  }
  set PO_Part_List(value: PartTracker[]) {
    this._PO_Part_List = value;
  }

  get Created_Date(): Date {
    return this._Created_Date;
  }
  set Created_Date(value: Date) {
    this._Created_Date = value;
  }
  get Updated_Date(): Date {
    return this._Updated_Date;
  }
  set Updated_Date(value: Date) {
    this._Updated_Date = value;
  }

  get Updated_By(): string {
    return this._Updated_By;
  }
  set Updated_By(value: string) {
    this._Updated_By = value;
  }
  get Created_By(): string {
    return this._Created_By;
  }
  set Created_By(value: string) {
    this._Created_By = value;
  }


}

export class PartTracker {

  private _PO_Part_No: string;
  private _PO_Part_Qty: number;
  private _PO_Part_Name: string;
  private _PO_Shipping_Date: Date;
  private _PO_Status: string;
  private _Completed_Date: Date;
  private _BoxInfo_List: BoxShippingInfo[];
  private _CheckOut_RawList: CheckOut[];
  private _Added_TO_Packing : boolean;
  private _CartonBoxNeeded : number;
  public POQuantity : number;
  public partCurrency : string;
  public InStock: number;
  public TakeFromStocks : TakeFromStock[] = [];
  public PIC: string;

  public RevNO: string;
  public MachineNo: string;


  //Generate Label
  private _PartPhoto : string;
  private _PackingListNo : string;
  private _InvoiceNo : string;
  private _CustomerName  = "";
  private _CustomerAddress : string;
  private _Carton_Weight : string;
  private _Part_Weight : string;
  private _QuantityPerBox: string
  //end

  public POS: string = ''
  public Reference: string = ''
  public ID: string = ''

  public subFrom: string = ''

  public subPart: PartTracker[] = [];

  //
  get QuantityPerBox(): string {
    return this._QuantityPerBox;
  }
  set QuantityPerBox(value: string) {
    this._QuantityPerBox = value;
  }
  get PartPhoto(): string {
    return this._PartPhoto;
  }
  set PartPhoto(value: string) {
    this._PartPhoto = value;
  }
  get PackingListNo(): string {
    return this._PackingListNo;
  }
  set PackingListNo(value: string) {
    this._PackingListNo = value;
  }
  get InvoiceNo(): string {
    return this._InvoiceNo;
  }
  set InvoiceNo(value: string) {
    this._InvoiceNo = value;
  }
  get CustomerName(): string {
    return this._CustomerName;
  }
  set CustomerName(value: string) {
    this._CustomerName = value;
  }
  get CustomerAddress(): string {
    return this._CustomerAddress;
  }
  set CustomerAddress(value: string) {
    this._CustomerAddress = value;
  }
  get Carton_Weight(): string {
    return this._Carton_Weight;
  }
  set Carton_Weight(value: string) {
    this._Carton_Weight = value;
  }
  get Part_Weight(): string {
    return this._Part_Weight;
  }
  set Part_Weight(value: string) {
    this._Part_Weight = value;
  }
  //





  get CartonBoxNeeded(): number {
    return this._CartonBoxNeeded;
  }
  set CartonBoxNeeded(value: number) {
    this._CartonBoxNeeded = value;
  }

  get Added_TO_Packing(): boolean {
    return this._Added_TO_Packing;
  }
  set Added_TO_Packing(value: boolean) {
    this._Added_TO_Packing = value;
  }

  get BoxInfo_List(): BoxShippingInfo[] {
    return this._BoxInfo_List;
  }
  set BoxInfo_List(value: BoxShippingInfo[]) {
    this._BoxInfo_List = value;
  }

  get CheckOut_RawList(): CheckOut[] {
    return this._CheckOut_RawList;
  }
  set CheckOut_RawList(value: CheckOut[]) {
    this._CheckOut_RawList = value;
  }
  get PO_Part_No(): string {
    return this._PO_Part_No;
  }
  set PO_Part_No(value: string) {
    this._PO_Part_No = value;
  }

  get PO_Part_Qty(): number {
    return this._PO_Part_Qty;
  }
  set PO_Part_Qty(value: number) {
    this._PO_Part_Qty = value;
  }

  get PO_Part_Name(): string {
    return this._PO_Part_Name;
  }
  set PO_Part_Name(value: string) {
    this._PO_Part_Name = value;
  }

  get PO_Shipping_Date(): Date {
    return this._PO_Shipping_Date;
  }
  set PO_Shipping_Date(value: Date) {
    this._PO_Shipping_Date = value;
  }

  get Completed_Date(): Date {
    return this._Completed_Date;
  }
  set Completed_Date(value: Date) {
    this._Completed_Date = value;
  }

  get PO_Status(): string {
    return this._PO_Status;
  }
  set PO_Status(value: string) {
    this._PO_Status = value;
  }
}

export class BoxShippingInfo {
  private _Box_No: string;
  private _Box_Status: string;
  private _Updated_Date: Date;
  private _Status: string;
  private _Packaging_Quantity: string;
  private _Weighting_Status: string;

  get Box_No(): string {
    return this._Box_No;
  }
  set Box_No(value: string) {
    this._Box_No = value;
  }
  get Box_Status(): string {
    return this._Box_Status;
  }
  set Box_Status(value: string) {
    this._Box_Status = value;
  }
  get Weighting_Status(): string {
    return this._Weighting_Status;
  }
  set Weighting_Status(value: string) {
    this._Weighting_Status = value;
  }
  set Status(value: string) {
    this._Status = value;
  }
  get Status(): string {
    return this._Status;
  }
  set Packaging_Quantity(value: string) {
    this._Packaging_Quantity = value;
  }

  get Packaging_Quantity(): string {
    return this._Packaging_Quantity;
  }

  get Updated_Date(): Date {
    return this._Updated_Date;
  }
  set Updated_Date(value: Date) {
    this._Updated_Date = value;
  }

}

export class CheckOut {
  private _Raw_PO: string;
  private _RawCheckOutList: RawCheckOut[];

  get Raw_PO(): string {
    return this._Raw_PO;
  }
  set Raw_PO(value: string) {
    this._Raw_PO = value;
  }

  get RawCheckOutList(): RawCheckOut[] {
    return this._RawCheckOutList;
  }
  set RawCheckOutList(value: RawCheckOut[]) {
    this._RawCheckOutList = value;
  }


}
export class RawCheckOut {
  private _Raw_ID: string;
  private _Raw_Name: string;
  private _CheckOut_Date: Date;
  private _CheckOut_Quantity: string;

  get Raw_ID(): string {
    return this._Raw_ID;
  }

  set Raw_ID(value: string) {
    this._Raw_ID = value;
  }
  get Raw_Name(): string {
    return this._Raw_Name;
  }
  set Raw_Name(value: string) {
    this._Raw_Name = value;
  }
  set CheckOut_Quantity(value: string) {
    this._CheckOut_Quantity = value;
  }
  get CheckOut_Quantity(): string {
    return this._CheckOut_Quantity;
  }

  get CheckOut_Date(): Date {
    return this._CheckOut_Date;
  }
  set CheckOut_Date(value: Date) {
    this._CheckOut_Date = value;
  }
}

