import { POStock } from './../Object_Classes/Part/Part';
import { AngularFireStorage } from '@angular/fire/storage';
import { RawMaterialInfo } from './../Object_Classes/RawMaterial/RawMaterial';
import { AngularFireDatabase } from '@angular/fire/database';
import { Part } from '../Object_Classes/Part/Part';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';


export class PartDB_controller {
  constructor(private db: AngularFireDatabase,private storage:AngularFireStorage, private firestore: AngularFirestore) {
  }
  private dateFormat = new DateFormatService();

  /************** Part ****************/
  async getPartList(): Promise<Part[]> {
    let PartList: Part[] = [];
    var snapshot = await this.db.database.ref('Part').once('value');
    //var snapshotPO = await this.db.database.ref('Purchase Order').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let part = new Part();

        part.Part_No = childSnapshot.key;
        part.Part_Name = childSnapshot.child('Part Name').val();
        part.Cycle_Time = childSnapshot.child('Cycle Time').val();
        part.Available_Machine = childSnapshot.child('Available Machine').val();
        part.Color = childSnapshot.child('Part Color').val();
        part.Part_Thickness = childSnapshot.child('Part Thickness').val();
        part.Part_Width = childSnapshot.child('Part Width').val();
        part.Part_Length = childSnapshot.child('Part Length').val();
        part.Part_Gusset = childSnapshot.child('Part Gusset').val();
        part.Part_Specification = childSnapshot.child('Part Specification').val();
        part.Printing_Style = childSnapshot.child('Printing Style').val();
        part.Ink_Info = childSnapshot.child('Ink Info').val();
        part.Counter = childSnapshot.child('Counter').val();
        part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
        part.customerID = childSnapshot.child('CustomerID').val();
        part.Part_Unit = childSnapshot.child('Part Unit').val();
        part.Embossed = childSnapshot.child('Embossed').val();
        part.Last_Update = new Date(childSnapshot.child("Last Update Date").val());
        part.Remarks = childSnapshot.child('Remark').val();
        
        PartList.push(part);
      });
    }


    return PartList;
  }

  async add_or_update_Part(_newPart: Part) {
    await this.db.database.ref('PartRaw Material').set(null).then(async r => {
      const partinfo = { };

      partinfo['Part Name'] = _newPart.Part_Name || '-';
      partinfo['Part Thickness'] = _newPart.Part_Thickness || '-';
      partinfo['Part Width'] = _newPart.Part_Width || '-';
      partinfo['Part Length'] = _newPart.Part_Length || '-';
      partinfo['Part Gusset'] = _newPart.Part_Gusset || '-';
      partinfo['Part Specification'] = _newPart.Part_Specification || '-';
      partinfo['Part Unit'] = _newPart.Part_Unit || '-';
      partinfo['Printing Style'] = _newPart.Printing_Style || '-';
      partinfo['Ink Info'] = _newPart.Ink_Info || '-';
      partinfo['Part Color'] = _newPart.Color || '-';
      partinfo['Counter'] = _newPart.Counter || 0;
      partinfo['Cycle Time'] = _newPart.Cycle_Time || '-';
      partinfo['CustomerID'] = _newPart.customerID || '-';
      partinfo['Embossed'] = _newPart.Embossed || '-';
      partinfo['Available Machine'] = _newPart.Available_Machine || '-';
      partinfo['Remark'] = _newPart.Remarks || '-';
      partinfo['Stock Quantity'] = _newPart.Stock_Quantity || 0;
      partinfo['Last Update Date'] = new Date().toISOString();
      
      await this.db.database.ref('Part/' + _newPart.Part_No).set(partinfo);

      const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
      const info = {
        date: new Date(),
        content:JSON.stringify(_newPart),
      };

      await this.firestore.collection('PartLog').doc(dateFormat).set({ Date: new Date() });
      await this.firestore.collection('PartLog').doc(dateFormat).collection('Part').add(info);
    });
  }

  async search_Part(Part_No: string): Promise<Part> {
    let searched_part = new Part;
    var childSnapshot = await this.db.database.ref('Part/'+Part_No).once('value');

    if (childSnapshot.exists()) {
      var get_Part_No = childSnapshot.key;

      //if (Part_No.match(get_Part_No)) {
        searched_part.Part_No = childSnapshot.key;
        searched_part.Part_Name = childSnapshot.child('Part Name').val();
        searched_part.Cycle_Time = childSnapshot.child('Cycle Time').val();
        searched_part.Available_Machine = childSnapshot.child('Available Machine').val();
        searched_part.Color = childSnapshot.child('Part Color').val();
        searched_part.Part_Thickness = childSnapshot.child('Part Thickness').val();
        searched_part.Part_Width = childSnapshot.child('Part Width').val();
        searched_part.Part_Length = childSnapshot.child('Part Length').val();
        searched_part.Part_Gusset = childSnapshot.child('Part Gusset').val();
        searched_part.Part_Specification = childSnapshot.child('Part Specification').val();
        searched_part.Printing_Style = childSnapshot.child('Printing Style').val();
        searched_part.Ink_Info = childSnapshot.child('Ink Info').val();
        searched_part.Counter = childSnapshot.child('Counter').val();
        searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
        searched_part.customerID = childSnapshot.child('CustomerID').val();
        searched_part.Part_Unit = childSnapshot.child('Part Unit').val();
        searched_part.Embossed = childSnapshot.child('Embossed').val();
        searched_part.Last_Update = new Date(childSnapshot.child("Last Update Date").val());
        searched_part.Remarks = childSnapshot.child('Remark').val();
      //}
    }

    return searched_part;
  }

  async search_Partial_Part(Part_No: string): Promise<Part[]> {
    let PartList: Part[] = [];
    var snapshot = await this.db.database.ref('Part').once('value');

    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let searched_part = new Part();
        var get_Part_No = childSnapshot.key;
        if (get_Part_No.includes(Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Part_Name = childSnapshot.child('Part Name').val();
          searched_part.Cycle_Time = childSnapshot.child('Cycle Time').val();
          searched_part.Available_Machine = childSnapshot.child('Available Machine').val();
          searched_part.Color = childSnapshot.child('Part Color').val();
          searched_part.Part_Thickness = childSnapshot.child('Part Thickness').val();
          searched_part.Part_Width = childSnapshot.child('Part Width').val();
          searched_part.Part_Length = childSnapshot.child('Part Length').val();
          searched_part.Part_Gusset = childSnapshot.child('Part Gusset').val();
          searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
          searched_part.customerID = childSnapshot.child('CustomerID').val();
          searched_part.Part_Unit = childSnapshot.child('Part Unit').val();
          searched_part.Embossed = childSnapshot.child('Embossed').val();
          searched_part.Last_Update = new Date(childSnapshot.child("Last Update Date").val());
          PartList.push(searched_part);
        }
      }));
    }

    return PartList;
  }

  delete_Part(part_no: string) {
    this.db.database.ref('/Part/' + part_no).set(null);
  }

  async getMaterial(id): Promise<RawMaterialInfo> {
    let searched_raw = new RawMaterialInfo();
    var snapshot = await this.db.database.ref('RawMaterial').child(id).once('value');
    if (snapshot.exists()) {
        searched_raw.Material_ID = snapshot.key;
        searched_raw.Material_Name = snapshot.child('_Material_Name').val();
        searched_raw.Material_Color = snapshot.child('_Material_Color').val();
        searched_raw.Material_Color_Code = snapshot.child('_Material_Color_Code').val();
        searched_raw.Material_Color_Ratio = snapshot.child('_Material_Color_Ratio').val();
        searched_raw.Material_Spen = snapshot.child('_Material_Spen').val();
        searched_raw.In_Stock = snapshot.child('_In_Stock').val();
        searched_raw.Quantity_Reserved = snapshot.child('_Quantity_Reserved').val();
        searched_raw.Unit = snapshot.child('_Unit').val();
        searched_raw.Quantity_PerBag = snapshot.child('_Quantity_PerBag').val();
        searched_raw.Unit_Price = snapshot.child('_Unit_Price').val();
        searched_raw.Updated_By = snapshot.child('_Updated_By').val();
        searched_raw.Created_By = snapshot.child('_Created_By').val();
        searched_raw.Created_Date = new Date(snapshot.child('_Created_Date').val());
        searched_raw.Last_Updated = new Date(snapshot.child('_Last_Updated').val());
        searched_raw.Raw_Type = snapshot.child('_Raw_Type').val();
        searched_raw.Carton_Weight = snapshot.child('_Carton_Weight').val();
    }
    return searched_raw;
  }

  async getSupplier(part:Part, supplierId, number): Promise<void>{
    var snapshot = await this.db.database.ref('Supplier').child(supplierId).once('value');
    if(snapshot.exists()){
      switch(number){
        case 1:part.Supplier1 = snapshot.child('_Supplier_Name').val();break;
        case 4:part.Supplier4 = snapshot.child('_Supplier_Name').val();break;
        case 5:part.Supplier5 = snapshot.child('_Supplier_Name').val();break;
        case 6:part.Supplier6 = snapshot.child('_Supplier_Name').val();break;
      }
    }
  }

  async getCustomer(part:Part, customerId): Promise<void>{
    var snapshot = await this.db.database.ref('Customer').child(customerId).once('value');
    if(snapshot.exists()){
      part.Customer_Name = snapshot.child('_Customer_Name').val();
      part.Customer_Address = snapshot.child('_Address').val();
    }
  }

  async search_PartWithStock(Part_No: string): Promise<Part> {
    let searched_part = new Part;
    var snapshot = await this.db.database.ref('Part').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( childSnapshot=>{
        var get_Part_No = childSnapshot.key;
        if (Part_No.match(get_Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
          const postocks : POStock[] = [];
          childSnapshot.child('Stock').forEach(child=>{
            const stock = new POStock();
            stock.BoxNumber = child.key;
            stock.Quantity = child.val();
            postocks.push(stock)
          })
          searched_part.POStocks = postocks;
        }
      });
    }

    return searched_part;
  }


}
