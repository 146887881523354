import { ScheduleTracker } from './../../../Services/Object_Classes/Machine/Machine';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, PageEvent, Sort } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Tubing } from 'src/app/Services/Object_Classes/Tubing/Tubing';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-qcinfo',
  templateUrl: './qcinfo.component.html',
  styleUrls: ['./qcinfo.component.css']
})
export class QCInfoComponent implements OnInit {

    //Pagination record
    length = 0;
    pageSize = 10;
    pageSizeOptions: number[] = [10, 25, 50];
    pageIndex = 0;
    offset = this.pageSize * this.pageIndex;
    search: string;
    sortedu = {
      active: 'weightDate',
      direction: 'desc'
    };
    Clone: ScheduleTracker[] = [];

  tableData :ScheduleTracker[]= [];
  title: any;
  constructor(
    private dialogRef: MatDialogRef<QCInfoComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService
  ) {
    this.tableData = cloneDeep(data.tableData);
    this.length = this.tableData.length;
    this.Clone = this.tableData.slice();
    this.limitList();
    this.title = data.title;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    this.Clone = this.tableData.slice();
    this.limitList();
  }
  sortData(sort: Sort) {
    this.Clone = this.tableData.slice();

    if (!sort.active || sort.direction === '' ) {
      this.Clone = this.tableData.slice();
      this.limitList();
      return;
    }

    this.Clone = this.Clone.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'poNo': return this.compare(a.Machine_Schedule_PO_No, b.Machine_Schedule_PO_No, isAsc);
        case 'partNo': return this.compare(a.Machine_Schedule_Part_No, b.Machine_Schedule_Part_No, isAsc);
        case 'partName': return this.compare(a.PartName, b.PartName, isAsc);
        case 'prodQty': return this.compare(a.Acc_Qty, b.Acc_Qty, isAsc);
        case 'totalWeight': return this.compare(this.calculateTubing(a.autotubingInfo), this.calculateTubing(b.autotubingInfo), isAsc);
        case 'totalTubingWeight': return this.compare(this.calculateTubing(a.tubingInfo), this.calculateTubing(b.tubingInfo), isAsc);
        case 'quantityDiff': return this.compare(
         this.calculateDiff(a.tubingInfo, a.autotubingInfo),
         this.calculateDiff(b.tubingInfo, b.autotubingInfo), isAsc);
        case 'date': return this.compareDate(a.DateFormatEnd, b.DateFormatEnd, isAsc);
        default: return 0;
      }
    });
    this.limitList();
  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }


  limitList() {
    this.Clone = this.Clone.slice(this.offset, (this.offset + this.pageSize));
  }

ngOnInit() {
}
cancel() {
  this.dialogRef.close(false);
}

calculateTubing(tubing: Tubing){
  return tubing.TubingInfos.map(a=>a.TubingAmount).reduce((a, b) => { return a + b }, 0)
}

calculateDiff(tubing:Tubing, autoTubing:Tubing){
  let manualTotal =  tubing.TubingInfos.map(a=>a.TubingAmount).reduce((a, b) => { return a + b }, 0);
  let autoTotal =  autoTubing.TubingInfos.map(a=>a.TubingAmount).reduce((a, b) => { return a + b }, 0);

  if(manualTotal === 0 || autoTotal === 0)
    return 0
  else
    return ((autoTotal/manualTotal) /autoTotal) *100

}

}
