import { ToastrService } from 'ngx-toastr';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-manage-raw-type',
  templateUrl: './manage-raw-type.component.html',
  styleUrls: ['./manage-raw-type.component.css']
})
export class ManageRawTypeComponent implements OnInit {

  rawTypes: any = [];
  RawDB_controllers: RawDB_controller = new RawDB_controller(this.db, this.firestore);
Type: any;
  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<ManageRawTypeComponent>,
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.setup();

    }

  cancel(): void {
    this.dialogRef.close();
  }

  setup(){
    this.RawDB_controllers.getRawTypeList().then(data=>{
      this.rawTypes = data;
      this.rawTypes = this.rawTypes.sort((a, b) => {
        return this.compare(a.value, b.value, true);
      })
    })
  }
  add(){
    if(!this.Type){
      this.toast.warning('Invalid Input!','Warning');
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Add this raw material type? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.RawDB_controllers.addType(this.Type).then(()=>{
          this.setup();
          this.Type = "";
          this.toast.success('New Type added','Added Successfully');

        })
      }
    })

  }
  delete(raw){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this raw material type ? ' + raw.value;

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.RawDB_controllers.delete_RawType(raw.ID).then(()=>{
          this.setup();
          this.Type = "";
          this.toast.success(raw.value + ' has been deleted','Deleted Successfully');
        })
      }
    })

  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
