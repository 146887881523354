import { ScheduleTracker } from './../../../../Services/Object_Classes/Machine/Machine';
import { Schedule } from './../../../../Services/Object_Classes/Schedule/Schedule';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-oee-calculation',
  templateUrl: './oee-calculation.component.html',
  styleUrls: ['./oee-calculation.component.css']
})
export class OeeCalculationComponent implements OnInit {

  Schedule: ScheduleTracker;
  constructor(
    private dialogRef: MatDialogRef<OeeCalculationComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,

  ) {
    this.Schedule = data;
  }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }

  Float(value){
    return parseFloat(value)
  }
}
