import { ConfirmationDialogComponent } from './../../../Shared/confirmation-dialog/confirmation-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFireDatabase } from '@angular/fire/database';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { AngularFireStorage } from '@angular/fire/storage';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { UpdateQuantityComponent } from './update-quantity/update-quantity.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-update-schedule',
  templateUrl: './update-schedule.component.html',
  styleUrls: ['./update-schedule.component.css']
})
export class UpdateScheduleComponent implements AfterViewInit {
  partController = new PartDB_controller(this.db, this.storage,this.firestore);
  PODB_controller = new PODB_controller(this.db);
  machine_controller: MachineDB_controller = new MachineDB_controller(this.db);
  private dateFormat = new DateFormatService();

  id: any;
  machine: any;
  scheduleInformation: any;
  po: any;
  part: any;
  partInfo: any;
  disabled: boolean;
  popUp: boolean = false;
  email:string;
  disabledPop = true;
  constructor(
    private dialogRef: MatDialogRef<UpdateScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private dialog: MatDialog,
  ) {
    this.id = data.id;
    let obj = JSON.parse(data.info)
    this.machine = obj.machine;
    this.po = obj.po;
    this.part = obj.part;
    this.minDate = new Date();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
  }
  public minDate: Date;
  public maxDate: moment.Moment;
  ngAfterViewInit(): void {
    this.spinner.show();
    this.getInfo();
  }

  async getInfo() {

    const datenow = new Date();
    const snapshot = await this.db.database.ref('Machine/' + this.machine + '/Schedule/' + this.id).once('value');
    const snapshotpo = await this.db.database.ref('Purchase Order/' + this.po + '/Part List' ).once('value');
    this.scheduleInformation = {
      key: snapshot.key,
      ...snapshot.val(),
    };
    snapshotpo.forEach(e=>{
      if(e.key === this.id){
        this.scheduleInformation['Reference'] = e.child('Reference').val()
        this.scheduleInformation['Information'] = e.child('Info').val()
        this.scheduleInformation['PO Quantity'] = e.child('PO Quantity').val()

      }
      else if(e.key === this.scheduleInformation['LinkedPart2ScheduleID']){
        this.scheduleInformation['Reference 2'] = e.child('Reference').val()
        this.scheduleInformation['Information 2'] = e.child('Info').val()
        this.scheduleInformation['PO Quantity 2'] = e.child('PO Quantity').val()

      }
      else if(e.key === this.scheduleInformation['LinkedPart3ScheduleID']){
        this.scheduleInformation['Reference 3'] = e.child('Reference').val()
        this.scheduleInformation['Information 3'] = e.child('Info').val()
        this.scheduleInformation['PO Quantity 3'] = e.child('PO Quantity').val()


      }
    })


    this.scheduleInformation['partNo2'] = new Date(this.scheduleInformation['LinkedPart2']);
    this.scheduleInformation['partNo3'] = new Date(this.scheduleInformation['LinkedPart3']);

    this.scheduleInformation['startAt'] = new Date(this.scheduleInformation['startAt']);
    this.scheduleInformation['endAt'] = new Date(this.scheduleInformation['endAt']);
    this.scheduleInformation['start'] = new Date(this.scheduleInformation['startAt']);
    this.scheduleInformation['end'] = new Date(this.scheduleInformation['endAt']);

    this.disabled = (this.scheduleInformation['Schedule Status'] === 'Done' || this.scheduleInformation['Schedule Status'] === 'In Progress') ? true : false
    if ((this.scheduleInformation['Schedule Status'] === 'Done' && new Date().getTime() < new Date(this.scheduleInformation['endAt']).getTime())) {
      const PO = await this.db.database.ref('Purchase Order/' + this.scheduleInformation['PO No'] + '/Part List/' + this.id).once('value');
      if(!PO.child('Added to packing').val()){

        this.disabledPop = false;

      }
      this.popUp = true;
    }
    this.scheduleInformation['start'].getTime() < datenow.getTime() ? true : false;
    this.spinner.hide();
    this.partController.search_Part(this.part).then(data => {
      this.partInfo = data;
    })

  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm(machine) {
    this.dialogRef.close(machine);
  }

  update() {

    if (this.scheduleInformation['Expected Quantity'] === "0" || !this.scheduleInformation['Expected Quantity']) {
      this.toast.warning("Cannot put zero or empty in quantity, Please enter again");
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to update the schedule';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        this.spinner.show();
        let updates = {};
        updates['/startAt'] = this.scheduleInformation.start;
        updates['/endAt'] = this.scheduleInformation.end;
        updates['/Expected Quantity'] = this.scheduleInformation['Expected Quantity'];
        await this.db.database.ref('Machine/' + this.machine + '/Schedule/' + this.id).update(updates);
        // await this.db.database.ref('Purchase Order/' + this.po + '/POS').set(this.scheduleInformation['POS']);
        await this.db.database.ref('Purchase Order/' + this.po + '/Part List/' + this.id).update({ 'Part Quantity': this.scheduleInformation['Expected Quantity'] });
        await this.db.database.ref('Purchase Order/' + this.po + '/Part List/' + this.id).update({ 'Reference': this.scheduleInformation['Reference'] });

        if(this.scheduleInformation['LinkedPart2ScheduleID']){
          await this.db.database.ref('Purchase Order/' + this.po + '/Part List/' + this.scheduleInformation['LinkedPart2ScheduleID']).update({ 'Reference': this.scheduleInformation['Reference 2'] });
        }
        if(this.scheduleInformation['LinkedPart3ScheduleID']){
          await this.db.database.ref('Purchase Order/' + this.po + '/Part List/' + this.scheduleInformation['LinkedPart3ScheduleID']).update({ 'Reference': this.scheduleInformation['Reference 3'] });
        }

        const update ={}
        update[this.po + '/_Updated_Date'] = new Date();
        update[this.po + '/_Updated_By'] = this.email;
        await this.db.database.ref('Purchase Order/').update(update);
        this.toast.success('Update successful');
        this.spinner.hide();

        this.dialogRef.close(true);
      }
    })
  }
  formvalid = true;

  ChangeDate() {
    const duration = this.scheduleInformation.endAt.getTime() - this.scheduleInformation.startAt.getTime();
    this.scheduleInformation.end = new Date(this.scheduleInformation.start.getTime() + duration);
    var mac = null;
    this.db.database.ref('Machine').child(this.machine).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);
      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.scheduleInformation.start, this.scheduleInformation.end, mac, this.scheduleInformation.key)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid = false
          return;
        }
        else {
          this.formvalid = true;
        }
      }
    })

  }

  estimateTime() {
    let value = (this.partInfo.Cycle_Time * (this.scheduleInformation['Expected Quantity'] / this.partInfo.Number_Cavity)) / 3600;
    this.scheduleInformation.end = new Date(this.scheduleInformation.start.getTime() + value * 60 * 60000);
    var mac = null;

    this.db.database.ref('Machine').child(this.machine).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);
      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.scheduleInformation.start, this.scheduleInformation.end, mac, this.scheduleInformation.key)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid = false
          return;
        }
        else {
          this.formvalid = true;
        }
      }
    })
  }

  delete() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this schedule?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        await this.PODB_controller.delete_Schedule(this.po, this.machine, this.id, this.scheduleInformation);
        this.dialogRef.close(true);
      }
    })
  }

  open() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    dialogConfig.disableClose = false;

    dialogConfig.data = {
      Quantity: this.scheduleInformation['Expected Quantity'],
      POQuantity: this.scheduleInformation['PO Quantity']

    };

    this.dialog.open(UpdateQuantityComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.scheduleInformation['Expected Quantity'] = result.Quantity
        this.scheduleInformation['PO Quantity'] = result.POQuantity
        this.estimateTime()
        this.updateQuantity()
      }
    })
  }
  async updateQuantity() {


    let updates = {};
    updates['/startAt'] = this.scheduleInformation.start;
    updates['/endAt'] = this.scheduleInformation.end;
    updates['/Expected Quantity'] = this.scheduleInformation['Expected Quantity'];
    updates['/PO Quantity'] = this.scheduleInformation['PO Quantity'];

    await this.db.database.ref('Machine/' + this.machine + '/Schedule/' + this.id).update(updates);
    await this.db.database.ref('Purchase Order/' + this.po + '/Part List/' + this.id).update({ 'Part Quantity': this.scheduleInformation['Expected Quantity'] });
    await this.db.database.ref('Purchase Order/' + this.po + '/Part List/' + this.id).update({ 'PO Quantity': this.scheduleInformation['PO Quantity'] });

    this.toast.success('Update successful');
    this.dialogRef.close(true);

  }

  getClass(){
    if(this.scheduleInformation['LinkedPart2'] && this.scheduleInformation['LinkedPart3'] ){
      return "col-4"
    }else if(this.scheduleInformation['LinkedPart2'] || this.scheduleInformation['LinkedPart3'] ){
      return "col-6"
    }else{
      return "col-12"
    }
  }

}
