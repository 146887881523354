import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'DigitalProductionManagement';


  /*constructor(@Inject(DOCUMENT) private document: Document, private router: Router) {

    const domain = document.location.hostname;
    if(domain === 'binsen-98177.firebaseapp.com' || domain === 'localhost'){
    }else{
      this.router.navigate(['/404']);
    }
  }*/
}
