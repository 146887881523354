import { PurchaseOrderRaw } from './../../../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-detail-po',
  templateUrl: './detail-po.component.html',
  styleUrls: ['./detail-po.component.css']
})
export class DetailPOComponent implements OnInit {

    po = new PurchaseOrderRaw();
    recFlag = false;
    checkFlag = false;
    flag1 = false;
    flag2 = false;
    ngOnInit() {
    }
    constructor(
      public dialogRef: MatDialogRef<DetailPOComponent>,
      @Inject(MAT_DIALOG_DATA) public data) {
        this.po = data;
        let result1 = this.po.PO_RawMaterials.find(e=>e.ReceivedDetailsList.length>0);
        let result2 = this.po.PO_RawMaterials.find(e=>e.PoInRawMaterialList.length>0);
        if(result1)
          this.flag1 = true;
        if(result2)
          this.flag2 = true;
      }

    close(): void {
      this.dialogRef.close();
    }
    integer(q){
      return parseFloat(q);
    }
    showRec(){
      this.recFlag = !this.recFlag;
    }
    showCheck(){
      this.checkFlag = !this.checkFlag

    }
    openImage(url) {
      window.open(url, '_blank');
   }

  }
