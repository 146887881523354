import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-job-tubing-details',
  templateUrl: './job-tubing-details.component.html',
  styleUrls: ['./job-tubing-details.component.css']
})
export class JobTubingDetailsComponent implements OnInit {

  job: any = [];
  constructor(
    private dialogRef: MatDialogRef<JobTubingDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data,

  ) {
    this.job = data;
  }
  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }

}
