import { OldStock } from './../../../Services/Object_Classes/RawMaterial/OldStock';
import { RawMaterialInfo } from './../../../Services/Object_Classes/RawMaterial/RawMaterial';
import { RawDB_controller } from './../../../Services/DB_Controller/RawDB_controller';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import jsPDF from 'jspdf';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-generate-old-qrcode',
  templateUrl: './generate-old-qrcode.component.html',
  styleUrls: ['./generate-old-qrcode.component.css']
})
export class GenerateOldQRCodeComponent implements OnInit {

    RawDB_controller: RawDB_controller = new RawDB_controller(this.db,this.firestore);
    elementType = NgxQrcodeElementTypes.URL;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
    qrCodes = [];
    detailsPO = [];
    Raws: RawMaterialInfo[] = []
    matSelectRaws : RawMaterialInfo[] = []
    searchRaws:any
    form: FormGroup;
    email:string;
    stocklist:OldStock[]= []
    constructor(
      private dialogRef: MatDialogRef<GenerateOldQRCodeComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private toast: ToastrService,
      private db: AngularFireDatabase,
      private dialog: MatDialog,
      private firestore: AngularFirestore,
      private angularFireAuth: AngularFireAuth,
      private fb: FormBuilder,
      private spinner: NgxSpinnerService
    ) {
      this.spinner.show()
      this.stocklist = data;
      this.angularFireAuth.authState.subscribe(auth => {
        this.email = auth.email;
      });
    }



    ngOnInit() {
      this.RawDB_controller.getRawList().then(data => {
        this.Raws = data;
        this.matSelectRaws = this.Raws.slice();
        this.spinner.hide();
      });

      const rawControl = new FormControl();
      const rawfilterControl = new FormControl();

      rawfilterControl.valueChanges.subscribe(() => {
        this.findraw(rawfilterControl);
      });
      rawControl.valueChanges.subscribe(() => {
        if (rawControl.value) {
          this.searchRaws = rawControl.value;
          const raw = this.Raws.find(p=>p.Material_Name === this.searchRaws);
        }
      });

      this.form = this.fb.group({
        rawControl: rawControl,
        rawfilterControl: rawfilterControl,
        quantity: new FormControl('', Validators.required),
        location: new FormControl('', Validators.required),
      });
    }

    findraw(raw){
      if (!this.Raws) { return; }
      const search = raw.value;
      this.matSelectRaws = this.Raws.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));

    }
    cancel() {
      this.dialogRef.close(false);
    }

    confirm() {
      const raw = this.Raws.find(p => p.Material_Name === this.searchRaws);

      // if(this.stocklist.find(s=>s.MaterialID === raw.Material_ID)){
      //   this.toast.error("This raw material already had old stock record","Please update instead of add")
      //   return;
      // }
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = '50%';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data = 'Add old stock for this raw?';
      const id = uuidv4();
      this.qrCodes = [];
      const value = {
        type:"OLDSTOCK",
        oldStockID: id,
        quantity: this.form.get('quantity').value,
        date: new Date().getTime(),
      };
      const qr = {
        id: 1,
        value: JSON.stringify(value)
      };
      this.qrCodes.push(qr);

      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
        if (result) {
          const oldstock = new OldStock();
          oldstock.MaterialID = raw.Material_ID;
          oldstock.MaterialName = raw.Material_Name;
          oldstock.Quantity = parseFloat(this.form.get('quantity').value);
          oldstock.Location = this.form.get('location').value;

          await this.RawDB_controller.addOldStock(oldstock, this.email,id);

          this.spinner.show();
          const doc = new jsPDF();
          const qrcode = document.getElementById("1");
          const imageData = this.getBase64Image(qrcode.firstChild.firstChild);
          doc.text('Material Name:' + raw.Material_Name, 20, 20);
          doc.addImage(imageData, 'JPG', 20, 25);
          doc.save(raw.Material_Name+'OLDQR.pdf');
          this.dialogRef.close(true);
          this.spinner.hide();
          this.qrCodes = [];
        }
      });
    }



    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    }




  }
